import React from 'react'

const Broucher = () => {
  return (
    <div className='container broucher-image'>
        <img src="/assets/img/1.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/2.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/3.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/4.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/5.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/6.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/7.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/8.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/9.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/10.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/11.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/12.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/13.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/14.jpg" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/15.jpg" alt="Broucher" style={{width: '100%'}}></img>
    </div>
  )
}

export default Broucher