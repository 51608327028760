import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import $ from 'jquery';
import { parseValue } from '../../utils/Common';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerLogisticsOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    const [orderImagesData, setOrderImagesData] = useState([]);
    const [showOrderImagesModal, setShowOrderImagesModal] = useState(false);
    const [showTest, setShowTest] = useState(false);

    useEffect(() => {
        getOrders();
        getDeliveryUsers();
    }, []);

    const getOrders = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
            'logistics': 1
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response.data.code === 'success') {
            setOrders(response.data.data);
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        } else {
            setOrders([]);
        }
    }

    const getDeliveryUsers = async () => {
        let data = {
            'action': 'read',
            'deliver_manager_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.data.code === 'success') {
            setDeliveryUsers(response.data.data);
        } else {
            setDeliveryUsers([]);
        }
    }

    const assignDeliver = async (orderId, deliverId, columnName) => {
        let data = {
            'action': 'update',
            'id': orderId,
            [columnName]: parseInt(deliverId)
        }
        let response = await common.apiRequest('post', '/delivery/assign-deassign-orders', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId, previousStatus) => {
        if (orderStatus == "process_amount_to_wallet" && previousStatus != "cancelled" && previousStatus != "returned") {
            common.alert('First please change the status to cancelled or returned', 'error');
            return;
        }
        if (previousStatus === "process_amount_to_wallet") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const generateInvoice = async (sellerId, orderId, invoiceType) => {
        let response = await common.getInvoiceData([sellerId], orderId, invoiceType);
        if (response.length === 0) {
            common.alert('No invoice', 'error');
            return;
        }
        navigate(
            '/generate-invoice',
            {
                state: {
                    invoiceData: response
                }
            }
        )
    }

    const uploadCancelReplaceReturnImages = async (event, orderItemId) => {
        const files = event?.target?.files;
        if (files.length > 0) {
          await common.uploadOrderImages(files, orderItemId, 'seller', 'seller_order', 1);
        } else {
          common.alert("No images to upload", "error");
        }
        $('#order_images').val(null);
    }

    const showOrderImages = async (orderItemId) => {
        let response = await common.fetchOrderImages(orderItemId);
        if (response && response.data && response.data.data && response.data.data.length > 0) {
            setOrderImagesData(response.data.data);
            setShowTest(true);
            setShowOrderImagesModal(true);
        } else {
            setShowTest(true);
            setOrderImagesData([]);
            setShowOrderImagesModal(false);
            common.alert("No Order Images Found", "info");
        }
    }

    const data = orders.map(data => ({
        orderId: data.order?.order_id || "NA",
        orderType: data.product_item 
            ? "Online Product Sale" 
            : data.seller_service 
                ? "Online Service Sale" 
                : "Offline Product Sale",
        sellerLogisticsBothOrder: (() => {
            const isLogistics = data.logistics_vendor_id == localStorage.getItem('userId');
            const isSeller = data.seller_id == localStorage.getItem('userId');
            const isOfflineSale = data.offline_sale_product_datum?.offline_sale?.seller_id == localStorage.getItem("userId");
            
            if ((isLogistics && isSeller) || isOfflineSale) return "Both";
            if (isLogistics) return "Logistics";
            return "Seller";
        })(),
        "Product Name": data.product_item 
            ? data.product_item.product.name 
            : data.seller_service 
                ? data.seller_service.service_name 
                : data.offline_sale_product_datum?.product_description || "NA",
        "product Description": data.product_item 
            ? data.product_item.product.description 
            : data.seller_service 
                ? data.seller_service.service_description 
                : data.offline_sale_product_datum?.product_description || "NA",
        "User InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'product')?.invoice_no || "NA",
        "seller InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'seller_product')?.invoice_no || "NA",
        "Seller InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'seller_product')?.invoice_no || "NA",
        "Logistics Seller InvoiceNo": 
        data.logistics_vendor_id > 0 
        ? data.order?.invoices?.find(inv => inv.invoice_type === 'logistics')?.invoice_no || "NA" 
        : "NA",
        "Logistics Price": 
        data.logistics_vendor_id > 0 
        ? data.order?.invoices?.find(inv => inv.invoice_type === 'logistics')?.price || 0 
        : 0,
        "Logistics Provider": data.logistics_order ? data.logistics_order.seller_service.user.user_business.business_name || "NA" : "NA",
            "Order Price": data.order_price || 0,
            "Tax Rate": data.offline_sale_product_datum 
            ? (data.offline_sale_product_datum.cgst_rate || 0) + 
              (data.offline_sale_product_datum.sgst_utgst_rate || 0) + 
              (data.offline_sale_product_datum.igst_rate || 0)
            : data.tax_percent || 0,
        "Taxable Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.taxable_supply || 0
            : data.taxable_amount || 0,
        "Igst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_rate || 0
            : data.igst_percent || 0,
        "Igst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_amount || 0
            : data.igst || 0,
        "Sgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_rate || 0
            : data.sgst_percent || 0,
        "Sgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_amount || 0
            : data.sgst || 0,
        "Cgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_rate || 0
            : data.cgst_percent || 0,
        "Cgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_amount || 0
            : data.cgst || 0,
        "Order Quantity": data.order_quantity || 0,
        "Order Date": data.order?.created_at,
        "Return CGST Amount": data.rcgst || 0,
        "Return CGST Rate": data.rcgst_percent || 0,
        "Return GST Rate": data.rgst_rate || 0,
        "Return IGST Amount": data.rigst || 0,
        "Return IGST Rate": data.rigst_percent || 0,
        "Return IRN": data.rirn || "",
        "Return IRN Date": data.rirn_date || "",
        "Credit Note Date": data.rnote_date || "",
        "Credit Note No": data.rnote_no || "",
        "Credit Note Supply Type": data.rnote_supply_type || "",
        "Credit Note Type": data.rnote_type || "",
        "Credit Note Value": data.rnote_value || 0,
        "Return SGST Amount": data.rsgst || 0,
        "Return SGST Rate": data.rsgst_percent || 0,
        "Return Taxable Value": data.rtaxable_value || 0,
        "Return UTGST Amount": data.rutgst || 0,
        "Return UTGST Rate": data.rutgst_percent || 0
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'orders.csv');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'orders.xlsx');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'orders.pdf');
    }

    const columnDefs = useMemo(() => [
        { 
            field: 'order_type', 
            headerName: 'Order Type',
            valueGetter: params => 
                params?.data?.type.toUpperCase() ?? "NA"
        },
        { field: 'order_item.order.order_id', headerName: 'Order ID'},
        { 
            field: 'order', 
            headerName: 'Order',
            valueGetter: params => 
                params.data.product_item ? "Online Product Sale" : 
                params.data.seller_service ? "Online Service Sale" : 
                "Offline Product Sale"
        },
        {
            field: 'created_at',
            headerName: 'Order Date',
        },
        { 
            field: 'productName', 
            headerName: 'Product Name',
            valueGetter: params => 
                params.data.order_item.product_item ? params.data.order_item.product_item.product.name : 
                params.data.order_item.seller_service ? params.data.order_item.seller_service.service_name : 
                params.data.order_item.offline_sale_product_datum ? params.data.order_item.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'productDescription', 
            headerName: 'Product Description',
            valueGetter: params => 
                params.data.order_item.product_item ? params.data.order_item.product_item.product.description : 
                params.data.order_item.seller_service ? params.data.order_item.seller_service.service_description : 
                params.data.order_item.offline_sale_product_datum ? params.data.order_item.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'sellerInvoiceNo', 
            headerName: 'Seller Invoice No',
            valueGetter: params => {
                return params?.data?.order_item?.invoice?.invoice_no ?? "NA";
            }
        },
        {
            field: 'order_price',
            headerName: 'Order Price',
            valueGetter: params => parseValue(params.data.order_price || 0,'float')
        },
        {
            field: 'taxable_value',
            headerName: 'Taxable Value',
            valueGetter: params => parseValue(params.data.taxable_value || 0,'float')
        },
        {
            field: 'gst_rate',
            headerName: 'GST Rate',
            valueGetter: params => parseValue(params.data.gst_rate || 0,'float')
        },
        {
            field: 'cgst',
            headerName: 'CGST Amount',
            valueGetter: params => parseValue(params.data.cgst || 0,'float')
        },
        {
            field: 'cgst_percent',
            headerName: 'CGST Rate',
            valueGetter: params => params.data.cgst_percent || 0
        },
        {
            field: 'igst',
            headerName: 'IGST Amount',
            valueGetter: params => parseValue(params.data.igst || 0,'float')
        },
        {
            field: 'igst_percent',
            headerName: 'IGST Rate',
            valueGetter: params => params.data.igst_percent || 0
        },
        {
            field: 'sgst',
            headerName: 'SGST Amount',
            valueGetter: params => parseValue(params.data.sgst || 0,'float')
        },
        {
            field: 'sgst_percent',
            headerName: 'SGST Rate',
            valueGetter: params => params.data.sgst_percent || 0
        },
        {
            field: 'utgst',
            headerName: 'UTGST Amount',
            valueGetter: params => parseValue(params.data.utigst || 0,'float')
        },
        {
            field: 'utgst_percent',
            headerName: 'UTGST Rate',
            valueGetter: params => params.data.utigst_percent || 0
        },
        {
            field: 'irn',
            headerName: 'IRN',
            valueGetter: params => params.data.irn || ''
        },
        {
            field: 'irn_date',
            headerName: 'IRN Date',
            valueGetter: params => params.data.irn_date || ''
        },
        { field: 'order_item.order_quantity', headerName: 'Order Quantity' },
        { 
            field: 'order_status', 
            headerName: 'Order Status',
            cellRenderer: params => {
                const getStatusOptions = () => {
                    if (params.data.order_item.product_item) {
                        return orderStatuses.product || {};
                    } else if (params.data.order_item.offline_sale_product_datum) {
                        return orderStatuses.offline_product || {};
                    } else if (params.data.order_item.seller_service) {
                        return orderStatuses.service || {};
                    }
                    return {};
                };

                const statusOptions = getStatusOptions();

                return (
                    <select 
                        id="order_status" 
                        value={params.data.order_item.order_status} 
                        className="form-control" 
                        onChange={(e) => changeOrderStatus(params.data.order_item.id, e.target.value, params.data.order_item.order_price, params.data.order_item.order.user_id, params.data.order_item.order_status)} 
                        style={{width: '130px'}}
                    >
                        {Object.entries(statusOptions).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                );
            }
        },
        { 
            headerName: 'Upload Order Images',
            cellRenderer: params => {
                return (
                    <input type='file' id="order_images" onChange={(e) => uploadCancelReplaceReturnImages(e, params.data.order_item.id)} multiple/>
                );
            }
        },
        { 
            headerName: 'View Order Images',
            cellRenderer: params => (
                <button type='button' className='form-control btn btn-info' onClick={() => showOrderImages(params.data.order_item.id)}>View Order Images</button>
            )
        },
        { field: 'order_completed_on', headerName: 'Order Completed On' },
        { field: 'order_cancelled_on', headerName: 'Order Cancelled On' },
        {
            headerName: 'Assign Delivery Person',
            cellRenderer: params => {
                return (
                    <select 
                        value={params.data.type == "order" ? params.data.order_item.deliver_id : params.data.type == "replace" ? params.data.order_item.replace_deliver_id : params.data.type == "return" ? params.data.order_item.return_deliver_id : 0 || 0} 
                        onChange={e => assignDeliver(params.data.order_item.id, e.target.value, params.data.type == "order" ? 'deliver_id' : params.data.type == "replace" ? 'replace_deliver_id' : params.data.type == "return" ? 'return_deliver_id' : '')}
                        className='form-control'
                    >
                        <option value="0">Select Delivery User</option>
                        {deliveryUsers.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                            </option>
                        ))}
                    </select>
                );
            }
        },
        {
            headerName: 'Generate Invoice',
            cellRenderer: params => (
                <>
                    <button className="btn btn-outline-success btn-sm usercardbtn1" onClick={() => generateInvoice(params.data.seller_id, params.data.order_item.id, ['logistics'])}>
                        Logistics Invoice
                    </button>
                </>
            )
        }
    ], [deliveryUsers, orderStatuses]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <h3>Logistics Orders</h3>
                </div>
                <div className='col-md-6'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            </div>
            <Form className='row'>
                <FormGroup className='col-md-4 row'>
                    <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                    <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                </FormGroup>
                <FormGroup className='col-md-4 row'>
                    <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                    <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                </FormGroup>
            </Form>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={orders}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={showOrderImagesModal} toggle={() => setShowOrderImagesModal(!showOrderImagesModal)} size="lg">
                <ModalHeader toggle={() => setShowOrderImagesModal(!showOrderImagesModal)}>Order Images</ModalHeader>
                <ModalBody>
                    {
                        orderImagesData && orderImagesData.length > 0 ?
                        <table>
                            <thead>
                                <th>Photo</th>
                                <th>Uploaded By</th>
                                <th>Type</th>
                                <th>Upload Date Time</th>
                            </thead>
                            <tbody>
                            {
                                orderImagesData.map((imageData) => {
                                    return (
                                    <>
                                        <tr>
                                            <td><img src={envConfig.api_url + imageData.photo} style={{ width: '100px', height: '100px', objectFit: 'cover' }} /></td>
                                            <td>{imageData.uploaded_via}</td>
                                            <td>{imageData.type}</td>
                                            <td>{imageData.created_at}</td>
                                        </tr>
                                    </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        :
                        <h4>No Order Images</h4>
                    }
                </ModalBody>
            </Modal>
        </>
    )
}