import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { Table, Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import moment from 'moment';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import { useCSVDownloader } from 'react-papaparse';
import { parseValue } from '../utils/Common';

import $ from 'jquery';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const OfflineOrders = () => {
    let navigate = useNavigate();
    const [offlineOrdersData, setOfflineOrdersData] = useState([]);
    const { CSVDownloader, Type } = useCSVDownloader();
    const getOfflineOrdersData = async () => {
        let data = {
            'action': 'read',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
            'user_id': localStorage.getItem('userId')
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            setOfflineOrdersData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }
    const downloadData = offlineOrdersData.flatMap((data, index) => (
        data.offline_sale_product_data?.map((product, productIndex) => ({
            S_No: index + 1,
            Date: data.created_at,
            Invoice_No: data.order?.invoice?.invoice_no || "N/A",
            Seller_Name: data.seller?.first_name || "N/A",
            Seller_User_ID: data.seller?.reg_id || "N/A",
            Items_Particular: product.product_description,
            Taxable_Amount: product.taxable_supply,
            Total_Amount: product.sale_amount,
            Delivery_status: data.order_status,
            Feedback: "N/A",
            Query: "N/A"
        })) || []
    ));

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(orderData) {
        let orderAmount = 0;
        for (let i = 0; i < orderData.offline_sale_product_data?.length; i++) {
            orderAmount += parseValue(orderData.offline_sale_product_data[i]['sale_amount'], 'float');
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            common.alert("Razorpay SDK failed to load. Are you online?", 'error');
            return;
        }
        var data = {
            'amount': orderAmount
        }
        const result = await common.apiRequest('post', '/payment/orders', data);
        if (!result) {
            common.alert("Server error. Are you online?", 'error');
            return;
        }
        const { amount, id: order_id, currency } = result.data.data;
        const receipt_id = result.data.receiptId;
        const options = {
            key: envConfig.razorpay_key_id,
            amount: amount.toString(),
            currency: currency,
            name: "Shree Raja Ram Raj Biz",
            description: "Place Order",
            image: {},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    receiptId: receipt_id
                };
                const result = await common.apiRequest('post', '/payment/success', data);
                common.alert(result.data.data.msg, 'success');
                placeOrder(result.data.paymentId, orderData, orderAmount);
            },
            theme: {
                color: "#61dafb",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const placeOrder = async (paymentId, orderData, orderAmount) => {
        var orderItems = [];
        var order = {
            'user_id': orderData.user_id,
            'address_id': orderData.user_address_id,
            'order_price': orderAmount,
            'logistics_vendor_service_id': 0,
            'logistics_vendor_id': 0,
            'logistics_vendor_price': 0,
            'payment_id': paymentId,
            'order_type': 'offline_product',
            'order_status': 'delivered',
            'logistic_orders': []
        }
        for (let i = 0; i < orderData.offline_sale_product_data?.length; i++) {
            let tempData = {
                'offline_sale_id': orderData.offline_sale_product_data[i].id,
                'seller_id': orderData.seller_id,
                'product_id': 0,
                'order_quantity': orderData.offline_sale_product_data[i].quantity,
                'order_price': orderData.offline_sale_product_data[i].sale_amount,
                'service_id': 0,
                'order_status': "delivered",
                'logistics_vendor_id': 0
            }
            orderItems.push(tempData);
        }
        var data = {
            'order': order,
            'orderItems': orderItems,
            'offline_order_id': orderData.id
        }
        let response = await common.apiRequest('post', '/orders/place-orders', data);
        if (response.data.code == "success") {
            common.alert(response.data.message);
            navigate('/orders/null');
        }
    }

    useEffect(() => {
        getOfflineOrdersData();
    }, []);
    const offlineOrderData = offlineOrdersData.flatMap((data, orderIndex) => {
        if (!data.offline_sale_product_data || data.offline_sale_product_data.length === 0) {
            return [{
                "S. No.": orderIndex + 1,
                "Invoice No": data.order?.invoices.find(invoice => invoice.invoice_type === "seller_product")?.invoice_no || "N/A",
                "Order Date": data.order?.created_at || "N/A",
                "Seller Name": `${data.seller?.first_name || "N/A"} ${data.seller?.middle_name || ""} ${data.seller?.last_name || "N/A"}`,
                "Seller Reg ID": data.seller?.reg_id || "N/A",
                "Product Description": "N/A",
                "Taxable Supply": "N/A",
                "Sale Amount": "N/A",
                "CGST Rate": "N/A",
                "CGST Amount": "N/A",
                "SGST/UTGST Rate": "N/A",
                "SGST/UTGST Amount": "N/A",
                "IGST Rate": "N/A",
                "IGST Amount": "N/A",
                "Order Status": "In Progress",
                "Payment Status": getPaymentStatus(data.status),
                "Feedback": "N/A",
                "Query": "N/A"
            }];
        }
    
        return data.offline_sale_product_data.map((product, productIndex) => {
            const orderStatus = getOrderStatus(data.offline_sale_product_data);
            
            return {
                "S. No.": `${orderIndex + 1}.${productIndex + 1}`,
                "Invoice No": data.order?.invoices.find(invoice => invoice.invoice_type === "seller_product")?.invoice_no || "N/A",
                "Order Date": data.order?.created_at || "N/A",
                "Seller Name": `${data.seller?.first_name || "N/A"} ${data.seller?.middle_name || ""} ${data.seller?.last_name || "N/A"}`,
                "Seller Reg ID": data.seller?.reg_id || "N/A",
                "Product Description": product.product_description || "N/A",
                "Taxable Supply": parseValue(product.taxable_supply || "N/A",'float'),
                "Sale Amount": parseValue(product.sale_amount || "N/A",'float'),
                "CGST Rate": parseValue(product.cgst_rate || "N/A",'float'),
                "CGST Amount": parseValue(product.cgst_amount || "N/A",'float'),
                "SGST/UTGST Rate": parseValue(product.sgst_utgst_rate || "N/A",'float'),
                "SGST/UTGST Amount": parseValue(product.sgst_utgst_amount || "N/A",'float'),
                "IGST Rate": parseValue(product.igst_rate || "N/A",'float'),
                "IGST Amount": parseValue(product.igst_amount || "N/A",'float'),
                "Order Status": orderStatus,
                "Payment Status": getPaymentStatus(data.status),
                "Feedback": product.order_items?.[0]?.feedbacks
                    ? product.order_items[0].feedbacks
                        .map(feedback => feedback.user_feedback)
                        .join(", ")
                    : "N/A",
                "Query": product.order_items?.[0]?.querys
                    ? product.order_items[0].querys
                        .map(query => query.user_query)
                        .join(", ")
                    : "N/A"
            };
        });
    });
    
    
    function getPaymentStatus(status) {
        switch (status) {
            case 1: return "Pending Payment";
            case 0: return "Entry From Seller Pending";
            default: return "Payment Already Done";
        }
    }
    
    function getOrderStatus(products) {
        if (products.length === 0) return "In Progress";
        
        const hasUndeliveredItems = products.some(
            product => product.order_items.length !== 0 && 
            product.order_items[0].order_status !== "delivered"
        );
        
        return hasUndeliveredItems ? "In Progress" : "Delivered";
    }
    const handleExportCSV = () => {
        exportToCSV(offlineOrderData, 'offlineOrderData');
    }
    
    const handleExportExcel = () => {
        exportToExcel(offlineOrderData, 'offlineOrderData');
    }
    
    const handleExportPDF = () => {
        exportToPDF(offlineOrderData, 'offlineOrderData');
    }
    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <br />
                    <h2><center>Offline Orders</center></h2>
                    <br />
                </div>
                
            </div>
            <div className='row tables'>
                <div className='col-md-12 '>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOfflineOrdersData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOfflineOrdersData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
            <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
            <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    </div>
                    <div className='table-responsive table-container'>
                    <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    {/* <th>Date</th> */}
                                    <th>Invoice No</th>
                                    <th>Order Date</th>
                                    <th>Seller Name</th>
                                    <th>Seller User ID</th>
                                    <th>Items/Particular</th>
                                    <th>Taxable Amount</th>
                                    <th>Total Amount</th>
                                    <th>CGST Rate</th>
                                    <th>CGST Amount</th>
                                    <th>SGST/UTGST Rate</th>
                                    <th>SGST/UTGST Amount</th>
                                    <th>IGST Rate</th>
                                    <th>IGST Amount</th>
                                    <th>Delivery Status</th>
                                    <th>Feedback</th>
                                    <th>Query</th>
                                    <th>Make Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offlineOrdersData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{index + 1}</td>
                                                {/* <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.created_at}</td> */}
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {data.order?.invoices.find(invoice => invoice.invoice_type === "seller_product")?.invoice_no || "N/A"}
                                                </td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.order?.created_at}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.seller?.first_name || "N/A"} {data.seller?.middle_name || "N/A"} {data.seller?.last_name || "N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.seller?.reg_id || "N/A"}</td>
                                                <td>{data.offline_sale_product_data?.[0]?.product_description || "N/A"}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.taxable_supply || "N/A", 'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.sale_amount || "N/A", 'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.cgst_rate || "N/A", 'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.cgst_amount || "N/A", 'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.sgst_utgst_rate || "N/A", 'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.sgst_utgst_amount || "N/A",   'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.igst_rate || "N/A",   'float')}</td>
                                                <td>{parseValue( data.offline_sale_product_data?.[0]?.igst_amount || "N/A",   'float')}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {data.offline_sale_product_data.length === 0 || !data.order 
                                                        ? 'In Progress'  
                                                        : 'Delivered'}
                                                </td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {data.offline_sale_product_data[0]?.order_items[0]?.feedbacks?.map((feedback, index) => (
                                                        <div key={feedback.id}>
                                                            {feedback.user_feedback}
                                                            {index < data.offline_sale_product_data[0].order_items[0].feedbacks.length - 1 && <br />}
                                                        </div>
                                                    )) || "N/A"}
                                                </td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {data.offline_sale_product_data[0]?.order_items[0]?.querys?.map((query, index) => (
                                                        <div key={query.id}>
                                                            {query.user_query}
                                                            {index < data.offline_sale_product_data[0].order_items[0].querys.length - 1 && <br />}
                                                        </div>
                                                    )) || "N/A"}
                                                </td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {
                                                        data.status === 1 ?
                                                            <button className='btn btn-primary btn-sm' onClick={() => displayRazorpay(data)}>Make Payment</button> : data.status === 0 ? "Entry From Seller Pending" :
                                                                "Payment Already Done"
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                data.offline_sale_product_data?.slice(1).map((product, productIndex) => (
                                                    <tr key={productIndex}>
                                                        <td>{product.product_description || "N/A"}</td>
                                                        <td>{parseValue(product.taxable_supply || "N/A",'float')}</td>
                                                        <td>{parseValue(product.sale_amount || "N/A",'float')}</td>
                                                        <td>{parseValue(product.cgst_rate || "N/A",'float')}</td>
                                                        <td>{parseValue(product.cgst_amount || "N/A",'float')}</td>
                                                        <td>{parseValue(product.sgst_utgst_rate || "N/A",'float')}</td>
                                                        <td>{parseValue(product.sgst_utgst_amount || "N/A",'float')}</td>
                                                        <td>{parseValue(product.igst_rate || "N/A",'float')}</td>
                                                        <td>{parseValue(product.igst_amount || "N/A",'float')}</td>
                                                    </tr>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        </>
    );
};

export default OfflineOrders;
