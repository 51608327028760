import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const DeliveryProduct = () => {
  
  let navigate = useNavigate();
  const [deliveryData, setDeliveryData] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('delivery_token')) {
      navigate('/delivery/login');
    }
    getDeliveryData();
  }, []);

  const getDeliveryData = async () => {
    const parameters = {
      "deliver_id": parseInt(localStorage.getItem("delivery_userId")),
      "start_date": $('#start-date').val(),
      "end_date": $('#end-date').val(),
      "order_id": $('#order-id').val().length == 16 ? $('#order-id').val() : null 
    };
    let response = await common.apiRequest('post', '/delivery/get-delivery-orders', parameters, 'delivery');
    let data = (response.data && response.data.data) ? response.data.data : [];
    setDeliveryData(data);
  };

  return (
    <>  
    <section>
      <div className="container py-3">
        <div className='row'>
          <div className='col-md-4'>
            <input type='date' id='start-date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getDeliveryData} className='form-control'/>
          </div>
          <div className='col-md-4'>
            <input type='date' id='end-date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getDeliveryData} className='form-control'/>
          </div>
          <div className='col-md-4'>
            <input type='text' id='order-id' placeholder="Search By Order ID" onInput={getDeliveryData} className='form-control'/>
          </div>
        </div>
        <br/>
        {
          deliveryData && deliveryData.length == 0 ?
          <>
            <h4 className='text-center'>No orders assigned yet for current month... <br/> Try Changing Filters...</h4>
          </>
          :
          deliveryData.map((data) => {
            return (
              <div className="card mb-5" key={data.id}>
                <div className="row g-0">
                  <div className="col-md-4">
                    <img className="img-fluid product_image" 
                         src={envConfig.api_url + 
                              (data.product_item ? data.product_item.product_image.split(',')[0] : 
                              data.seller_service ? data.seller_service.service_image : null)} 
                         alt="Product" />
                  </div>
                  <div className="col-md-8 px-3">
                    <div className="card-block px-3">
                      <h4 className="card-title py-3">
                        {data.product_item ? data.product_item.product.name : data.seller_service ? data.seller_service.service_name : null}
                      </h4>
                      <div className="col-12 mt-3">
                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Order ID :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                            {data.order ? data.order.order_id : 'Not Found'}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Order Date :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                            {data.created_at ? data.created_at : 'Not Found'}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Order Status :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                          {data.order_status ? data.order_status.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : 'Not Found'}
                          </div>
                        </div>
                        
                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Customer Name :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                            {data.order.user.first_name + " " + data.order.user.last_name || ""}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Phone No :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                            {data.order.user.mobile_phone || ""}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <label className="col-sm-4 col-md-3">
                            <h5>Deliver Address :</h5>
                          </label>
                          <div className="col-sm-8 col-md-9" style={{ fontSize: "large" }}>
                            {data?.order?.user_address?.address || ""}
                          </div>
                        </div>
                        
                        <button 
                          className="btn btn-primary btn-sm" 
                          onClick={() => navigate('/delivery/product/details', { state: data })}>
                          More details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </section>
    </>
  );
}

export default DeliveryProduct;
