import React, {useState,useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
import { exportToCSV, exportToExcel, exportToPDF } from '../utils/Exports';
import $ from 'jquery';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const common = require('../utils/Common');
const moment = require('moment');

const UserWallet = () => {
    const [walletData, setWalletData] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
        getWalletData();
    }, []);

    const getWalletData = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/users/get-wallet-data', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setWalletData(data);
    }
    const data = walletData.map((order, index) => {
       
        return {
            "S. No.": index + 1,
        "Transaction Date": moment(order.created_at).format('YYYY-MM-DD HH:mm:ss'),
        "Order Id": order.order_id || 'N/A',
        "Credit": order.credit || '0',
        "Debit": order.debit || '0',
        "Running Balance": order.running_balance || '0',
        };
    });
    const handleExportCSV = () => {
        exportToCSV(data, 'Wallet');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'Wallet');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'Wallet');
    }
    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <ol className="col-12 breadcrumb bg-transparent">
                    <li className="breadcrumb-item "><Link to="/" className="text-dark">Home</Link></li>
                    <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">user-account</Link></li>
                    <li className="breadcrumb-item active"><Link to="#" className="text-dark">Wallet</Link></li>
                </ol>
            </div>
            <div className="col-md-12">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getWalletData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getWalletData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>      
                    <div>
            <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
            <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
            <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

        </div>
            
                </div>
            <div className='row'>
            <div className='col-md-12 table-responsive table-container'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Transaction Date</th>
                        <th>Order Id</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            walletData.map((data) => {
                                return (
                                    <tr>
                                        <td>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => navigate(`/orders/${data.order_id}`)}>
                                            {data.order_id}
                                        </td>
                                        <td>{data.credit}</td>
                                        <td>{data.debit}</td>
                                        <td>{data.running_balance}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>       
            </div>
            </div>
        </div>
        </>
    )
}

export default UserWallet;