import React, { useEffect, useState } from 'react'
import { Table} from 'reactstrap';
import $, { data } from 'jquery';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');


const StockInfo = () => {

    useEffect(()=>{
        getStockInfo()
    },[])
    const [stockData, setStockData] = useState([]);
    console.log(stockData)
    const getStockInfo = async () => {
        const parameters = {
            action: 'read',
            'seller_id': localStorage.getItem('sellerId'),
        };   
        let response = await common.apiRequest('post', '/seller/left-Stock', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setStockData(data);
    }

    const addRemoveStock = async (productId, stockQuantity, remainingStock, type) => {
        if (!$('#add_stock_'+productId).val() || parseInt($('#add_stock_'+productId).val()) < 0) {
            common.alert("Please enter valid stock quantity", "error");
            return;
        } 
        if (type == "remove" && parseInt(remainingStock) < parseInt($('#add_stock_'+productId).val())) {
            common.alert("Stock to remove is greater than available stock", "error");
            return;
        }
        const parameters = {
            action: 'update',
            id: productId,
            qty_in_stock: type == "add" ? parseInt(stockQuantity) + parseInt($('#add_stock_'+productId).val()) : parseInt(stockQuantity) - parseInt($('#add_stock_'+productId).val())
        }
        let response = await common.apiRequest('post', '/seller/left-Stock', parameters);
        if (response.data.code == "success") {
            common.alert(response.data.message, 'success');
        } else {
            common.alert(response.data.error, 'error');
        }
        $('#add_stock_'+productId).val(null);
        getStockInfo();
    }
    const data = stockData.map((item, index) => ({
        "Product": item.product.name,
        "Product SKU": item.product_sku,
        "Product Image": item.product_image,
        "Product Price": item.price,
        "Item Weight": item.item_weight,
        "HSN Code": item.hsn_code,
        "Quantity In Stock": item.qty_in_stock,
        "Remaining Stock": item.remaining_stock,
        "Total Utalized Sales": item.total_sales
    }));


const handleExportCSV = () => {
    exportToCSV(data, 'stock info');
}

const handleExportExcel = () => {
    exportToExcel(data, 'stock info');
}

const handleExportPDF = () => {
    exportToPDF(data, 'stock info');
}
  return (
   <>
              <div className='stock-component'>
            <div>
            <div className='stock-header col-md-12'>
                       <h2>Stock Info</h2> 
                </div>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                <div className='row tables'>
                <div className='col-md-12 '>
                <div className='table-responsive table-container'>
        <Table striped bordered hover>
            <thead>
                <tr className='table-primary'>
                    <th>Product</th>
                    <th>Product SKU</th>
                    <th>Product Image</th>
                    <th>Product Price</th>
                    <th>Item Weight</th>
                    <th>HSN Code</th>
                    <th>Quantity In Stock</th>
                    <th>Remaining Stock</th>
                    <th>Total Utalized Sales</th>
                    <th>Add More Stock</th>
                </tr>
            </thead>
            <tbody>
                {
                    stockData.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="2">
                                <center>No data Available</center>
                            </td>
                        </tr>
                    :
                    stockData.map((data) => {
                        return (
                            <>
                                <tr>
                                    <td>{data.product.name}</td>
                                    <td>{data.product_sku}</td>
                                    <td><img src={envConfig.api_url + data.product_image} style={{width: 100, height: 100, objectFit: 'cover'}}/></td>
                                    <td>{data.price}</td>
                                    <td>{data.item_weight}</td>
                                    <td>{data.hsn_code}</td>
                                    <td>{data.qty_in_stock}</td>
                                    <td>{data.remaining_stock}</td>
                                    <td>{data.total_sales}</td>
                                    <td>
                                        <input type='number' className='form-control' id={`add_stock_${data.id}`} placeholder='Add/Remove Stock'/>
                                        <button className='btn btn-success' onClick={() => addRemoveStock(data.id, data.qty_in_stock, data.remaining_stock, 'add')}>ADD</button>
                                        <button className='btn btn-danger' onClick={() => addRemoveStock(data.id, data.qty_in_stock, data.remaining_stock, 'remove')}>REMOVE</button>
                                    </td>
                                </tr>

                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        </div>
                    </div>
                    </div>
            </div>
               
        </div>
   </>
  )
}

export default StockInfo