import React,{useState,useEffect} from 'react'
import { Link, useParams, useNavigate,useLocation } from 'react-router-dom';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');




 function ServiceDetailsView() {
  let navigate = useNavigate();
  const location = useLocation();
  const attributes = useParams();
  const [inCart, setInCart] = useState([]);
  const [serviceData, setAllServiceData] = useState(); // Initialize state with null
  var [feedbackStars, setFeedbackStars] = useState(0);
  var [allReviews, setAllReviews] = useState([]);
  
  useEffect(() => {
    getService();
    checkCart(); // Check cart on component mount or when service_id changes
    getAllReviews();

  }, [attributes.service_id]);
  
  const getService = async () => {
    const data = {
      id: attributes.service_id,
      action: "read"
    };
    
    let response = await common.apiRequest('post', '/seller/seller-service-details', data);
    console.log("API Response:", response);

    if (response.data.code === 'success') {
      console.log("Data received from API:", response.data.data);
      setAllServiceData(response.data.data[0]); 
    } else {
      common.alert(response.data.error, 'error');
    }
  };
  const addToCart = async (serviceId, sellerId, sellerAddressId) => {
    let cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    let newCartItem = {
        'product_id': 0,
        'product_item_id': 0,
        'quantity_purchased': 1,
        'user_id': localStorage.getItem('userId'),
        'supply_pincode': 0,
        'service_id': serviceId,
        'type': 'service',
        'seller_id': sellerId,
        'after_pack_item_weight': 0,
        'seller_address_id': sellerAddressId,
        'action': 'create',
        'active': 0
    };
    let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
    let data = (response.data) ? response.data : [];
    newCartItem.cart_id = data.cart_id;
    delete newCartItem.user_id;
    delete newCartItem.action;
    cartItems.push(newCartItem);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));

    // Properly update the state
    setInCart((prevInCart) => [...prevInCart, serviceId]);
    common.alert(data.message, 'success');
};


const removeFromCart = async (serviceId) => {
  let cartItems = JSON.parse(localStorage.getItem('cartItems'));
  if (cartItems) {
      const cartItemToRemove = cartItems.find(o => o.service_id == serviceId);
      if (!cartItemToRemove) return; 
      cartItemToRemove.action = "delete";
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
      let data = (response.data) ? response.data : [];

      const updatedCartItems = cartItems.filter(item => item.service_id != serviceId);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

      // Properly update the state
      setInCart((prevInCart) => prevInCart.filter(id => id !== serviceId));
      common.alert(data.message, 'success');
  }
};

const checkCart = () => {
  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const serviceIdsInCart = cartItems.map(item => item.service_id);
  if (serviceIdsInCart.includes(parseInt(attributes.service_id))) {
    setInCart([parseInt(attributes.service_id)]);
  } else {
    setInCart([]);
  }
};
const changeFeedbackStars = (value) => {
  setFeedbackStars(value);
  for (let i = 1; i <= 5; i++) {
    document.getElementById(i + 'star').style.color = '#ddd';
  }
  for (let i = 1; i <= value; i++) {
    document.getElementById(i + 'star').style.color = '#ffd700';
  }
}

const postFeedback = async (e) => {
  e.preventDefault();
  var parameters = {
    "product_item_id": 0,
    "service_id": attributes.service_id,
    "rating": feedbackStars,
    "review": document.getElementById('feedback').value,
    "user_id": localStorage.getItem('userId')
  };
  let response = await common.apiRequest('post', '/products/post-product-review', parameters);
  common.alert(response.data.message);
  document.getElementById('feedback').value = "";
  changeFeedbackStars(0);
  getAllReviews();
}

const getAllReviews = async () => {
  var parameters = {
    "service_id": attributes.service_id,
  };
  let response = await common.apiRequest('post', '/products/get-product-review', parameters);
  if (response.data.data) {
    var first = 0; var second = 0; var third = 0; var fourth = 0; var fifth = 0;
    response.data.data.map((review) => {
      if (review.rating == 5) {
        fifth++;
      } else if (review.rating == 4) {
        fourth++;
      } else if (review.rating == 3) {
        third++;
      } else if (review.rating == 2) {
        second++;
      } else if (review.rating == 1) {
        first++;
      }
    });
    let total = response.data.data.length;
    if (total != 0) {
      first = (first * 100) / total;
      second = (second * 100) / total;
      third = (third * 100) / total;
      fourth = (fourth * 100) / total;
      fifth = (fifth * 100) / total;
    }
    document.getElementById('total-five-star').style.width = fifth + '%';
    document.getElementById('total-four-star').style.width = fourth + '%';
    document.getElementById('total-three-star').style.width = third + '%';
    document.getElementById('total-two-star').style.width = second + '%';
    document.getElementById('total-one-star').style.width = first + '%';
    setAllReviews(response.data.data);
  }
}
function calculateAverageRating(productReviews) {
  if (!productReviews || productReviews.length === 0) {
      return 0;
  }
  const sum = productReviews.reduce((acc, review) => acc + review.rating, 0);
  const average = sum / productReviews.length;
    return Math.round(average * 10) / 10;
}
const averageRating = calculateAverageRating(
  serviceData?.product_reviews || []
);
    return (
  <React.Fragment>
  <div class="container-fluid">
    <div class="row">
      <ol class="col-12 breadcrumb bg-transparent">
        <li class="breadcrumb-item"><Link to="/service" class="text-dark">Home</Link></li>
        <li class="breadcrumb-item active"><Link to="./index.html" class="text-dark">service details</Link></li>
      </ol>  
    </div>
  </div>


  <div className='py-5'>
        <div className='container-fluid'>
          <div className='row'>
          {serviceData && (
              <>
            <div className='col-lg-6'>
              
              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <Link data-fslightbox="mygalley" className="rounded-4" target="_blank" data-type="image" to="">
                  <img style={{ maxWidth: "100%", maxHeight: "60vh", margin: "auto" }} className="rounded-4 fit" src={envConfig.api_url + serviceData.service_image} />
                </Link>
              </div>
              
              
              
            </div>
            
            <div className='col-lg-6'>
              <div className="ps-lg-3">
                <h4 className="title text-dark">
                {serviceData.service_name}
                </h4>
                <div className="d-flex flex-row my-3">
                  <div className="text-warning star mb-1">
                  {(() => {
                    const totalRating = averageRating || 0;
                    const fullStars = Math.floor(Math.max(0, totalRating));
                    const halfStars = (totalRating % 1) >= 0.5 ? 1 : 0;
                    const emptyStars = Math.max(0, 5 - fullStars - halfStars);

                    const stars = [];
                    for (let i = 0; i < fullStars; i++) {
                      stars.push(<i key={`full-${i}`} className="fa fa-star"></i>);
                    }
                    if (halfStars > 0) {
                      stars.push(<i key="half" className="fas fa-star-half-alt"></i>);
                    }
                    for (let i = 0; i < emptyStars; i++) {
                      stars.push(<i key={`empty-${i}`} className="fa fa-star-o"></i>);
                    }

                    return (
                      <>
                        {stars}
                        <span className="ms-1">
                          &nbsp;{totalRating}
                        </span>
                      </>
                    );
                  })()}
                  <br/>
                  </div>
                 
                </div>

                <div className="mb-3">
                  <span className="h5"><i className="fas fa-rupee-sign"/>{serviceData.price}</span>
                  <span className="text-muted">/per visit</span>
                </div>
                <div className="mb-3">
                <dt className="col-3">Service description:</dt>
                <dd className="col-9">{serviceData.service_description}</dd>
                </div>
                <div className="mb-3">
                <dt className="col-3">Service provider:</dt>
                <dd className="col-9">{serviceData.service_by}</dd>
                </div>


                <div className="row">
                  <dt className="col-3">Deliverd By:</dt>
                  <dd className="col-9">{(new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)).toString().slice(0, 15))}</dd>

                  <dt className="col-3">Fastest Delivery:</dt>
                  <dd className="col-9">Tomorrow</dd>

                 
                </div>

                <hr />
              <div className="row mb-5 ml-1">
                </div>
            
                <div className='row'>
                    <div className='col-md-4 mt-5'>
                        {
                                                    !inCart.includes(serviceData.id) ? 
                                                    <button className="btn1 btn-primary shadow-0" onClick={() => addToCart(serviceData.id, serviceData.seller_id, serviceData.seller_address_gst)}>Add To Cart</button>
                                                    :
                                                    <button className="btn1 btn-primary shadow-0" onClick={() => removeFromCart(serviceData.id)}>Remove From Cart</button>
                                                }
                    </div>
                    <div className='col-md-4 mt-5'>
                        {/* <Link to="/cart" className="btn1 btn-warning shadow-0" onClick={buyNow}> Buy now </Link> */}
                    </div>
                    <div className='col-md-4 mt-5'>
                        <Link to="/" className="btn1 btn-warning shadow-0"> <i className="me-1 fa fa-shopping-basket"></i> More shopping </Link>
                    </div>
                </div>

                
              </div>
            </div>
            </>
            )}
          </div>
          
        </div>
      </div> 
      <div className="bg-light border-top py-4">
  <div className="container-fluid">
    <div className="row gx-4">
      <div className="col-lg-12 mb-4">
        <div className="border rounded-2 px-3 py-2 bg-white">
          <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100 active" id="ex1-tab-1" data-toggle="tab" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Specification</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-2" data-toggle="tab"  href="#ex1-pills-2" role="tab" aria-controls="ex1-pills-2" aria-selected="false">Buyer Feedback</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-3" data-toggle="tab" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Shipping info</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-4" data-toggle="tab" href="#ex1-pills-4" role="tab" aria-controls="ex1-pills-4" aria-selected="false">Seller profile</a>
            </li>
          </ul>
          <div className="tab-content" id="ex1-content">
            <div className="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">

            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
            <div className='container'>
          <div className=" row">
          <div className="col-md-12">
            <h4 className="text-black front-weight-bold ">Customer Reviews</h4>
            <div className="dropdown-divider"></div>
          </div>
          <div className="col-md-5">
            <span className="rating">5 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-five-star"></div>
            </div>
            <span className="rating">4 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-four-star"></div>
            </div>
            <span className="rating">3 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-three-star"></div>
            </div>
            <span className="rating">2 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-two-star"></div>
            </div>
            <span className="rating">1 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-one-star"></div>
            </div>
          </div>

          <div className="col-md-7">
            <div className='row'>
              <div className='col-md-2'>
                <label for="feedback" className="form-label font-weight-bold">Your Feedback :</label>
              </div>
              <div className='col-md-10'>
                <ul className="list-inline rating-list">
                  <li><i className="fa fa-star pl-3" id="5star" onClick={() => changeFeedbackStars(5)}></i></li>
                  <li><i className="fa fa-star pl-3" id="4star" onClick={() => changeFeedbackStars(4)}></i></li>
                  <li><i className="fa fa-star pl-3" id="3star" onClick={() => changeFeedbackStars(3)}></i></li>
                  <li><i className="fa fa-star pl-3" id="2star" onClick={() => changeFeedbackStars(2)}></i></li>
                  <li><i className="fa fa-star" id="1star" onClick={() => changeFeedbackStars(1)}></i></li>
                </ul>
                <textarea className="form-control" id="feedback" name="feedback" rows="6"></textarea>
                <button type="submit" className="mt-2 btn btn-primary" onClick={postFeedback}>Send Feedback</button>
              </div>
            </div>
          </div>

          <div className='col-md-12 mt-5'>
            <h4 className="text-capitalize">Top Reviews</h4>
            <div className="row mt-3">
              {
                allReviews.map((review) => {
                  let filledStars = [];
                  let unFilledStars = [];
                  for (let i = 1; i <= review.rating; i++) {
                    filledStars.push(1);
                  }
                  if (review.rating != 5) {
                    for (let i = review.rating; i < 5; i++) {
                      unFilledStars.push(1);
                    }
                  }
                  return (
                    <>
                      <div className="col-md-12">
                        <Link to="#" className="text-dark" style={{ textDecoration: "none" }}><img src="/assets/img/empty-avatar.png" width="34"
                          height="34" className="rounded-circle mr-2" />{review.user.first_name} {review.user.last_name}</Link>
                        <br />
                        <div className='mt-2'>
                          {
                            filledStars.map(() => {
                              return (
                                <i className='fa fa-star text-warning pr-2' style={{ fontSize: "22px" }}></i>
                              )
                            })
                          }
                          {
                            unFilledStars.map(() => {
                              return (
                                <i className='fa fa-star-o text-warning pr-2' style={{ fontSize: "22px" }}></i>
                              )
                            })
                          }
                        </div>
                        <p className='mt-2'>{review.review}</p>
                      </div>
                    </>
                  );
                })
              }
            </div>
            <div className="dropdown-divider mt-3"></div>

          </div>
        </div>
     

      </div>
            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">
             
            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-4" role="tabpanel" aria-labelledby="ex1-tab-4">
             
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>
        </React.Fragment>
    )
}
export default ServiceDetailsView;