import React from 'react';
import { useLocation } from 'react-router-dom'
import "./footer.css"
const CMSData = () => {
  const location = useLocation();
  const { value } = location.state;
  return (
    <>
      <div className='container footerdata'>
        <div className='data' dangerouslySetInnerHTML={{__html: value}}></div>
      </div>
    </>
  )
}

export default CMSData