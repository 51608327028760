import React, { useEffect, useState } from 'react';
import { Button, CardBody, Card, FormGroup, Label, Input, CardHeader, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './business.css';
import $ from 'jquery';
import envConfig from '../config/envConfig';
const common = require('../utils/Common');

const Business = (props) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [filePath, setFilePath] = useState("");
  const [data, setData] = useState({
    business_name: '',
    business_person_name: '',
    nature_of_business: '',
    pan_no: '',
    business_address: '',
    mobile: '',
    email: '',
    bank_account_no: '',
    bank_name: '',
    bank_address: '',
    ifsc_code: '',
  });
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

	const uploadGstCerImage = async (file) => {
        var fileData = new FormData();
        fileData.append('user_gst_image', file);
        var response = await common.apiRequest('post', '/users/user_gst_image', fileData);
		    setFilePath(response.data?.message??'');
    }
  useEffect(() => {
    document.title = "Business";
  }, []);

  let navigate = useNavigate();

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    const isValid = /^\d{10}$/.test(value);
    setIsValidMobile(isValid);
    setData(prevData => ({
      ...prevData,
      mobile: value
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setData(prevData => ({
      ...prevData,
      [id]: value
    }));
  };

  const validateStep = (id) => {
    let isValid = true;
    $(`#step-${id} :input`).each(function () {
      let input = $(this);
      if (input.attr('id') !== undefined && input.attr('id') !== 'gst_no_other_state' && input.val() === "") {
        common.alert("Please fill all fields", 'error');
        isValid = false;
        return false;
      }
    });
    return isValid;
  };

  const nextStep = async (id) => {
    if (id === 1 || id === 2) {
      if (!validateStep(id)) {
        return;
      }
    }

    if (id === 2) {
      if (!isTermsChecked) {
        common.alert("Please accept the Terms and Conditions", 'error');
        return;
      }
      data['user_id'] = localStorage.getItem('userId');
      data['user_gst_certificate'] = filePath;
      let response = await common.apiRequest('post', '/users/go-to-business', data);
      if (response.status === 200) {
        if (response.data.code === 'success') {
          common.alert("Business Registered", 'success');
          localStorage.setItem('userType', 'seller');
          localStorage.setItem('sellerId', response.data.sellerId);
        } else {
          common.alert("Something went wrong", 'error');
          navigate("/home");
        }
      } else {
        common.alert("Something went wrong", 'error');
        navigate("/home");
      }
    }

    $(`#step-${id}`).hide();
    $(`#step-${id + 1}`).show();
  };

  if (!localStorage.getItem('token')) {
    return navigate('/login');
  }

  return (
    <div className='container-fluid'>
      <Col className='cards business_card'>
        <Card className='col-md-3 col-sm-7'>
          <CardHeader className='card-headers'>Business profile</CardHeader>
          <CardBody>
            <div className='btm-border'></div>
            <div id='step-1'>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="business_name">Business Name</Label>
                <Input type="text" className='inputs' id="business_name" placeholder="Business Name" value={data.business_name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="business_person_name">Business Person Name</Label>
                <Input type="text" className='inputs' id="business_person_name" placeholder="Name of person" value={data.business_person_name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="nature_of_business">Nature Of Business</Label>
                <Input type="text" className='inputs' id="nature_of_business" placeholder="Business Nature" value={data.nature_of_business} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="pan_no">Business Pan</Label>
                <Input type="text" className='inputs' id="pan_no" placeholder="Business PAN" value={data.pan_no} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="business_address">Office/ Business Address</Label>
                <Input type="text" className='inputs' id="business_address" placeholder="techno line block-1" value={data.business_address} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="mobile">Mobile Number</Label>
                <Input type="text" className={`inputs ${isValidMobile ? '' : 'is-invalid'}`} id="mobile" placeholder="987654321" value={mobileNumber} onChange={handleMobileChange} />
                {!isValidMobile && <div className="invalid-feedback">Please enter a valid 10-digit mobile number.</div>}
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="email">Alternate E Mail ID</Label>
                <Input type="email" className='inputs' id="email" placeholder="abc@123" value={data.email} onChange={handleInputChange} />
              </FormGroup>
              <Button type='button' className='submit-button' onClick={() => nextStep(1)}>Proceed</Button>
            </div>

            <div id='step-2' style={{ display: 'none' }}>
              <p>Enter your bank account details</p>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="bank_account_no">Bank Account No.</Label>
                <Input type="number" className='inputs' id="bank_account_no" placeholder="0987654321" value={data.bank_account_no} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="bank_name">Bank Name</Label>
                <Input type="text" className='inputs' id="bank_name" placeholder="SBI" value={data.bank_name} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="bank_address">Bank Branch Address</Label>
                <Input type="text" className='inputs' id="bank_address" placeholder="ABC-21 block" value={data.bank_address} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                <Label className="me-sm-2" for="ifsc_code">RTGS/NEFT/IFSC Code</Label>
                <Input type="text" className='inputs' id="ifsc_code" placeholder="ABC56789" value={data.ifsc_code} onChange={handleInputChange} />
              </FormGroup>
              <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2" for="gst_certificate">GST Certificate</Label>
				        <Input type="file" id="gst_certificate" onChange={(e) => uploadGstCerImage(e.target.files[0])} />
				      </FormGroup>        
							{
								filePath ? 
								<img className='gst-image' src={envConfig.api_url + filePath} />
								: ""
							}
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />{' '}
                  I accept the <a href="#" onClick={toggleModal}>Terms and Conditions</a>
                </Label>
              </FormGroup>
              <Button type='button' className='submit-button' onClick={() => nextStep(2)}>Submit</Button>
            </div>

            <div id='step-3' style={{ display: 'none' }}>
              <p>Congratulations now you are a business partner of us. Welcome to SR3 World.</p>
              <a href="/SHome"><Button type='button' className='submit-button'>Seller Home</Button></a>
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Terms and Conditions</ModalHeader>
          <ModalBody>
		  <div dangerouslySetInnerHTML={{__html:props.cmsData.terms_and_condition_seller}} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </Col>
    </div>
  );
}

export default Business;
