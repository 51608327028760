import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { parseValue } from '../../utils/Common';
import $ from 'jquery';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

const UserRefeanceDetails = () => {
    const [userData, setUserData] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [modal, setModal] = useState(false);
    const [commissionPercentOnlineProduct, setCommissionPercentOnlineProduct] = useState(0);
    const [commissionPercentOfflineProduct, setCommissionPercentOfflineProduct] = useState(0);
    const [commissionPercentService, setCommissionPercentService] = useState(0);
    const [orderDetailsType, setOrderDetailsType] = useState(null);
    const [commissionType, setCommissionType] = useState('sale_retail');

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true
    }), []);

    const retainerColumnDefs = [
        { field: 'reg_id', headerName: 'Reg Id', width: 100 },
        { field: 'first_name', headerName: 'First Name' },
        { field: 'last_name', headerName: 'Last Name' },
        { 
            field: 'retainer_order_amount_online', 
            headerName: 'Total Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_taxable_order_amount_online', 
            headerName: 'Total Taxable Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_percent_online', 
            headerName: 'Commission Percent Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_online', 
            headerName: 'Commission Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_order_amount_offline', 
            headerName: 'Total Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_taxable_order_amount_offline', 
            headerName: 'Total Taxable Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_percent_offline', 
            headerName: 'Commission Percent Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_offline', 
            headerName: 'Commission Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_order_amount_service', 
            headerName: 'Total Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_taxable_order_amount_service', 
            headerName: 'Total Taxable Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_percent_service', 
            headerName: 'Commission Percent Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'retainer_commission_service', 
            headerName: 'Commission Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        {
            headerName: 'Total Commission',
            valueGetter: params => {
                const total = parseFloat(params.data.retainer_commission_online) + 
                            parseFloat(params.data.retainer_commission_offline) + 
                            parseFloat(params.data.retainer_commission_service);
                return parseValue(total.toFixed(2), 'float');
            }
        },
        {
            headerName: 'TDS',
            valueGetter: params => {
                const total = (parseFloat(params.data.retainer_commission_online) + 
                             parseFloat(params.data.retainer_commission_offline) + 
                             parseFloat(params.data.retainer_commission_service)) * 0.10;
                return parseValue(total.toFixed(2), 'float');
            }
        },
        {
            headerName: 'Receiveable Commission',
            valueGetter: params => {
                const total = parseFloat(params.data.retainer_commission_online) + 
                            parseFloat(params.data.retainer_commission_offline) + 
                            parseFloat(params.data.retainer_commission_service);
                const tds = total * 0.10;
                return parseValue((total - tds).toFixed(2), 'float');
            }
        },
        {
            headerName: 'Actions',
            cellRenderer: params => (
                <button 
                    className="btn btn-primary btn-sm" 
                    onClick={() => getOrderDetails(
                        params.data.order_item_ids,
                        params.data.retainer_commission_percent_online,
                        params.data.retainer_commission_percent_offline,
                        params.data.retainer_commission_percent_service,
                        'retainer'
                    )}
                >
                    View Order Details
                </button>
            )
        }
    ];

    const saleRetailColumnDefs = [
        { field: 'reg_id', headerName: 'Reg Id', width: 100 },
        { field: 'first_name', headerName: 'First Name' },
        { field: 'middle_name', headerName: 'Middle Name' },
        { field: 'last_name', headerName: 'Last Name' },
        { 
            field: 'total_user_shopping_amount_taxable', 
            headerName: 'User Total Taxable Shopping',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { field: 'variant', headerName: 'Variant' },
        { 
            field: 'total_shopping_online_product', 
            headerName: 'Total Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_shopping_online_product', 
            headerName: 'Total Taxable Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_online_product', 
            headerName: 'Commission Percent Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_shopping_offline_product', 
            headerName: 'Total Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_shopping_offline_product', 
            headerName: 'Total Taxable Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_offline_product', 
            headerName: 'Commission Percent Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_shopping_service', 
            headerName: 'Total Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_shopping_service', 
            headerName: 'Total Taxable Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_service', 
            headerName: 'Commission Percent Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { field: 'ref_user_reg_id', headerName: 'Referral User Id' },
        { 
            field: 'total_order_amount_shopping_online_product', 
            headerName: 'Total Order Amount Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_shopping_online_product', 
            headerName: 'Taxable Order Amount Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_shopping_online_product', 
            headerName: 'Commission Shopping Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_order_amount_shopping_offline_product', 
            headerName: 'Total Order Amount Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_shopping_offline_product', 
            headerName: 'Taxable Order Amount Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_shopping_offline_product', 
            headerName: 'Commission Shopping Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_order_amount_shopping_service', 
            headerName: 'Total Order Amount Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_shopping_service', 
            headerName: 'Taxable Order Amount Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_shopping_service', 
            headerName: 'Commission Shopping Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        {
            headerName: 'TDS Shopping',
            valueGetter: params => {
                const total = (parseFloat(params.data.commission_shopping_online_product) + 
                             parseFloat(params.data.commission_shopping_offline_product) + 
                             parseFloat(params.data.commission_shopping_service)) * 0.10;
                return parseValue(total.toFixed(2), 'float');
            }
        },
        {
            headerName: 'Receiveable Shopping',
            valueGetter: params => {
                const total = parseFloat(params.data.commission_shopping_online_product) + 
                            parseFloat(params.data.commission_shopping_offline_product) + 
                            parseFloat(params.data.commission_shopping_service);
                return parseValue((total - (total * 0.10)).toFixed(2), 'float');
            }
        },
        { 
            field: 'total_order_amount_retail_online_product', 
            headerName: 'Total Order Amount Retail Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_retail_online_product', 
            headerName: 'Taxable Order Amount Retail Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_retail_online_product', 
            headerName: 'Commission Percent Retail Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_retail_online_product', 
            headerName: 'Commission Retail Online Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_order_amount_retail_offline_product', 
            headerName: 'Total Order Amount Retail Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_retail_offline_product', 
            headerName: 'Taxable Order Amount Retail Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_retail_offline_product', 
            headerName: 'Commission Percent Retail Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_retail_offline_product', 
            headerName: 'Commission Retail Offline Product',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_order_amount_retail_service', 
            headerName: 'Total Order Amount Retail Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'total_taxable_amount_retail_service', 
            headerName: 'Taxable Order Amount Retail Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_percent_retail_service', 
            headerName: 'Commission Percent Retail Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        { 
            field: 'commission_retail_service', 
            headerName: 'Commission Retail Service',
            valueFormatter: params => parseValue(params.value, 'float')
        },
        {
            headerName: 'TDS Retail',
            valueGetter: params => {
                const total = (parseFloat(params.data.commission_retail_online_product) + 
                             parseFloat(params.data.commission_retail_offline_product) + 
                             parseFloat(params.data.commission_retail_service)) * 0.10;
                return parseValue(total.toFixed(2), 'float');
            }
        },
        {
            headerName: 'Receiveable Retail',
            valueGetter: params => {
                const total = parseFloat(params.data.commission_retail_online_product) + 
                            parseFloat(params.data.commission_retail_offline_product) + 
                            parseFloat(params.data.commission_retail_service);
                return parseValue((total - (total * 0.10)).toFixed(2), 'float');
            }
        },
        {
            headerName: 'Actions',
            cellRenderer: params => (
                <div className="d-flex flex-column">
                    {params.data.order_item_ids_shopping && (
                        <button 
                            className="btn btn-primary btn-sm mb-2 width-auto" 
                            onClick={() => getOrderDetails(
                                params.data.order_item_ids_shopping,
                                params.data.commission_percent_online_product,
                                params.data.commission_percent_offline_product,
                                params.data.commission_percent_service,
                                'shopping'
                            )}
                        >
                            View Shopping Order Details
                        </button>
                    )}
                    {params.data.order_item_ids_retail && (
                        <button 
                            className="btn btn-primary btn-sm width-auto"
                            onClick={() => getOrderDetails(
                                params.data.order_item_ids_retail,
                                params.data.commission_percent_retail_online_product,
                                params.data.commission_percent_retail_offline_product,
                                params.data.commission_percent_retail_service,
                                'retail'
                            )}
                        >
                            View Retail Order Details
                        </button>
                    )}
                </div>
            )
        }
    ];

    const orderDetailsColumnDefs = [
        { 
            field: 'index', 
            headerName: 'S.No', 
            valueGetter: params => params.node.rowIndex + 1 
        },
        { 
            field: 'order.order_id', 
            headerName: 'Order Id',
            valueGetter: params => params.data.order?.order_id || 'NA'
        },
        { 
            field: 'order.created_at', 
            headerName: 'Order Date',
            valueGetter: params => params.data.order?.created_at || 'NA'
        },
        {
            field: 'invoice_no',
            headerName: 'Invoice No',
            valueGetter: params => {
                const invoice = params.data.order?.invoices?.find(invoice =>
                    invoice.invoice_type === (orderDetailsType === "shopping" || orderDetailsType === "retainer" ? 'product' : 'seller_product')
                );
                return invoice?.invoice_no || 'NA';
            }
        },
        {
            field: 'product_name',
            headerName: 'Product Name',
            valueGetter: params => 
                params.data.product_item?.product.name || 
                params.data.offline_sale_product_datum?.product_description || 
                params.data.seller_service?.service_name || 
                'NA'
        },
        {
            field: 'order_price',
            headerName: 'Order Price',
            valueGetter: params => params.data.product_id ? params.data.order_price : 0
        },
        {
            field: 'taxable_amount',
            headerName: 'Taxable Amount',
            valueGetter: params => params.data.product_id ? params.data.taxable_amount : 0
        },
        {
            field: 'logistics_order_price',
            headerName: 'Logistics Order Price',
            valueGetter: params => params.data.logistics_order?.order_price || 0
        },
        {
            field: 'logistics_taxable_value',
            headerName: 'Logistics Taxable Value',
            valueGetter: params => params.data.logistics_order?.taxable_value || 0
        },
        {
            field: 'online_commission',
            headerName: 'Online Commission %',
            valueGetter: params => params.data.product_id || params.data.logistics_order ? commissionPercentOnlineProduct : 0
        },
        {
            field: 'offline_sale_amount',
            headerName: 'Offline Sale Amount',
            valueGetter: params => params.data.offline_sale_id && params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.sale_amount : 0
        },
        {
            field: 'offline_taxable_supply',
            headerName: 'Offline Taxable Supply',
            valueGetter: params => params.data.offline_sale_id && params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.taxable_supply : 0
        },
        {
            field: 'offline_commission',
            headerName: 'Offline Commission %',
            valueGetter: params => params.data.offline_sale_id ? commissionPercentOfflineProduct : 0
        },
        {
            field: 'service_order_price',
            headerName: 'Service Order Price',
            valueGetter: params => params.data.service_id ? params.data.order_price : 0
        },
        {
            field: 'service_taxable_amount',
            headerName: 'Service Taxable Amount',
            valueGetter: params => params.data.service_id ? Number(params.data.taxable_amount) : 0
        },
        {
            field: 'service_commission',
            headerName: 'Service Commission %',
            valueGetter: params => params.data.service_id ? commissionPercentService : 0
        },
        {
            field: 'total_taxable_amount',
            headerName: 'Total Taxable Amount',
            valueGetter: params => {
                const data = params.data;
                return parseValue(
                    parseFloat(data.taxable_amount) + 
                    parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0) * 
                    (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100,
                    'float'
                );
            }
        },
        {
            field: 'tds_amount',
            headerName: 'TDS Amount',
            valueGetter: params => {
                const data = params.data;
                return parseValue(
                    (parseFloat(data.taxable_amount) + 
                    parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0)) * 
                    (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 * 
                    0.10,
                    'float'
                );
            }
        },
        {
            field: 'net_amount',
            headerName: 'Net Amount',
            valueGetter: params => {
                const data = params.data;
                return parseValue(
                    parseFloat(data.taxable_amount) + 
                    parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0) * 
                    (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 - 
                    data.taxable_amount * 
                    (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 * 
                    0.10,
                    'float'
                );
            }
        }
    ];

    const fetchReferralData = async () => {
        if (!$('#start_date').val() || !$('#end_date').val() || !$('#commission_type').val()) {
            common.alert("Fill all filters (Start Date, End Date & Commission Type)", "error");
            return;
        }
        setCommissionType($('#commission_type').val());
        var parameters = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            user_id: localStorage.getItem('userId'),
            fetch_approved: $('#fetch_approved').is(':checked'),
            retainer_commission: $('#commission_type').val() == "retainer" ? 1 : 0
        };
        let response = await common.apiRequest('post', '/admin/fetch-user-commission', parameters);
        
        if (response.data && response.data.code === 'success') {
            setUserData(response.data.data);
        }
    };

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
        setCommissionPercentOnlineProduct(0);
        setCommissionPercentOfflineProduct(0);
        setCommissionPercentService(0);
        setOrderDetailsType(null);
    }

    const getOrderDetails = async (orderIds, commissionPercentOnlineProduct, commissionPercentOfflineProduct, commissionPercentService, type) => {
        let data = { 'ids': orderIds }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
        if (response.status == 200) {
            if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
                setCommissionPercentOnlineProduct(commissionPercentOnlineProduct);
                setCommissionPercentOfflineProduct(commissionPercentOfflineProduct);
                setCommissionPercentService(commissionPercentService);
                setOrderDetailsType(type);
            } else {
                common.alert(response.data.error, "error");
            }
        } else {
            common.alert(response.data.error, "error");
        }
    }
    const rowHeight = 80;
    const retainerColumnDefsdata = userData.map((item, index) => ({
        "S.No": index + 1,
        reg_id: item.reg_id,
        first_name: item.first_name,
        last_name: item.last_name,
        retainer_order_amount_online: parseValue(item.retainer_order_amount_online, 'float'),
        retainer_taxable_order_amount_online: parseValue(item.retainer_taxable_order_amount_online, 'float'),
        retainer_commission_percent_online: parseValue(item.retainer_commission_percent_online, 'float'),
        retainer_commission_online: parseValue(item.retainer_commission_online, 'float'),
        retainer_order_amount_offline: parseValue(item.retainer_order_amount_offline, 'float'),
        retainer_taxable_order_amount_offline: parseValue(item.retainer_taxable_order_amount_offline, 'float'),
        retainer_commission_percent_offline: parseValue(item.retainer_commission_percent_offline, 'float'),
        retainer_commission_offline: parseValue(item.retainer_commission_offline, 'float'),
        retainer_order_amount_service: parseValue(item.retainer_order_amount_service, 'float'),
        retainer_taxable_order_amount_service: parseValue(item.retainer_taxable_order_amount_service, 'float'),
        retainer_commission_percent_service: parseValue(item.retainer_commission_percent_service, 'float'),
        retainer_commission_service: parseValue(item.retainer_commission_service, 'float'),
        total_commission: parseValue(
            (
                parseFloat(item.retainer_commission_online || 0) +
                parseFloat(item.retainer_commission_offline || 0) +
                parseFloat(item.retainer_commission_service || 0)
            ).toFixed(2),
            'float'
        ),
        tds: parseValue(
            (
                (
                    parseFloat(item.retainer_commission_online || 0) +
                    parseFloat(item.retainer_commission_offline || 0) +
                    parseFloat(item.retainer_commission_service || 0)
                ) * 0.10
            ).toFixed(2),
            'float'
        ),
        receivable_commission: parseValue(
            (
                parseFloat(item.retainer_commission_online || 0) +
                parseFloat(item.retainer_commission_offline || 0) +
                parseFloat(item.retainer_commission_service || 0) -
                (
                    (
                        parseFloat(item.retainer_commission_online || 0) +
                        parseFloat(item.retainer_commission_offline || 0) +
                        parseFloat(item.retainer_commission_service || 0)
                    ) * 0.10
                )
            ).toFixed(2),
            'float'
        )
    }));
    
    const saleRetailColumnDefsdata = userData.map((user, index) => ({
        "S.No": index + 1,
        reg_id: user.reg_id || "",
        first_name: user.first_name || "",
        middle_name: user.middle_name || "",
        last_name: user.last_name || "",
        total_user_shopping_amount_taxable: parseValue(user.total_user_shopping_amount_taxable, 'float'),
        variant: user.variant || "",
        total_shopping_online_product: parseValue(user.total_shopping_online_product, 'float'),
        total_taxable_shopping_online_product: parseValue(user.total_taxable_shopping_online_product, 'float'),
        commission_percent_online_product: parseValue(user.commission_percent_online_product, 'float'),
        total_shopping_offline_product: parseValue(user.total_shopping_offline_product, 'float'),
        total_taxable_shopping_offline_product: parseValue(user.total_taxable_shopping_offline_product, 'float'),
        commission_percent_offline_product: parseValue(user.commission_percent_offline_product, 'float'),
        total_shopping_service: parseValue(user.total_shopping_service, 'float'),
        total_taxable_shopping_service: parseValue(user.total_taxable_shopping_service, 'float'),
        commission_percent_service: parseValue(user.commission_percent_service, 'float'),
        ref_user_reg_id: user.ref_user_reg_id || "",
        total_order_amount_shopping_online_product: parseValue(user.total_order_amount_shopping_online_product, 'float'),
        total_taxable_amount_shopping_online_product: parseValue(user.total_taxable_amount_shopping_online_product, 'float'),
        commission_shopping_online_product: parseValue(user.commission_shopping_online_product, 'float'),
        total_order_amount_shopping_offline_product: parseValue(user.total_order_amount_shopping_offline_product, 'float'),
        total_taxable_amount_shopping_offline_product: parseValue(user.total_taxable_amount_shopping_offline_product, 'float'),
        commission_shopping_offline_product: parseValue(user.commission_shopping_offline_product, 'float'),
        total_order_amount_shopping_service: parseValue(user.total_order_amount_shopping_service, 'float'),
        total_taxable_amount_shopping_service: parseValue(user.total_taxable_amount_shopping_service, 'float'),
        commission_shopping_service: parseValue(user.commission_shopping_service, 'float'),
        TDS_Shopping: parseValue(
            (
                (parseFloat(user.commission_shopping_online_product) || 0) +
                (parseFloat(user.commission_shopping_offline_product) || 0) +
                (parseFloat(user.commission_shopping_service) || 0)
            ) * 0.10, 
            'float'
        ),
        Receiveable_Shopping: parseValue(
            (
                (parseFloat(user.commission_shopping_online_product) || 0) +
                (parseFloat(user.commission_shopping_offline_product) || 0) +
                (parseFloat(user.commission_shopping_service) || 0) -
                (
                    ((parseFloat(user.commission_shopping_online_product) || 0) +
                    (parseFloat(user.commission_shopping_offline_product) || 0) +
                    (parseFloat(user.commission_shopping_service) || 0)) * 0.10
                )
            ).toFixed(2), 
            'float'
        ),
        total_order_amount_retail_online_product: parseValue(user.total_order_amount_retail_online_product, 'float'),
        total_taxable_amount_retail_online_product: parseValue(user.total_taxable_amount_retail_online_product, 'float'),
        commission_percent_retail_online_product: parseValue(user.commission_percent_retail_online_product, 'float'),
        commission_retail_online_product: parseValue(user.commission_retail_online_product, 'float'),
        total_order_amount_retail_offline_product: parseValue(user.total_order_amount_retail_offline_product, 'float'),
        total_taxable_amount_retail_offline_product: parseValue(user.total_taxable_amount_retail_offline_product, 'float'),
        commission_percent_retail_offline_product: parseValue(user.commission_percent_retail_offline_product, 'float'),
        commission_retail_offline_product: parseValue(user.commission_retail_offline_product, 'float'),
        total_order_amount_retail_service: parseValue(user.total_order_amount_retail_service, 'float'),
        total_taxable_amount_retail_service: parseValue(user.total_taxable_amount_retail_service, 'float'),
        commission_percent_retail_service: parseValue(user.commission_percent_retail_service, 'float'),
        commission_retail_service: parseValue(user.commission_retail_service, 'float'),
        TDS_Retail: parseValue(
            (
                (parseFloat(user.commission_retail_online_product) || 0) +
                (parseFloat(user.commission_retail_offline_product) || 0) +
                (parseFloat(user.commission_retail_service) || 0)
            ) * 0.10, 
            'float'
        ),
        Receiveable_Retail: parseValue(
            (
                (parseFloat(user.commission_retail_online_product) || 0) +
                (parseFloat(user.commission_retail_offline_product) || 0) +
                (parseFloat(user.commission_retail_service) || 0) -
                (
                    ((parseFloat(user.commission_retail_online_product) || 0) +
                    (parseFloat(user.commission_retail_offline_product) || 0) +
                    (parseFloat(user.commission_retail_service) || 0)) * 0.10
                )
            ).toFixed(2), 
            'float'
        ),
    }));
    
    const orderDetailsData = orderDetails.map((data, index) => ({
        "S.No": index + 1,
        "Order Id": data.order?.order_id || 'NA',
        "Order Date": data.order?.created_at || 'NA',
        "Invoice No": data.order?.invoices?.find(invoice => 
            invoice.invoice_type === (orderDetailsType === "shopping" || orderDetailsType === "retainer" ? 'product' : 'seller_product')
        )?.invoice_no || 'NA',
        "Product Name": data.product_item?.product.name || 
                        data.offline_sale_product_datum?.product_description || 
                        data.seller_service?.service_name || 
                        'NA',
        "Order Price": data.product_id ? data.order_price : 0,
        "Taxable Amount": data.product_id ? data.taxable_amount : 0,
        "Logistics Order Price": data.logistics_order?.order_price || 0,
        "Logistics Taxable Value": data.logistics_order?.taxable_value || 0,
        "Online Commission %": data.product_id || data.logistics_order ? commissionPercentOnlineProduct : 0,
        "Offline Sale Amount": data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.sale_amount : 0,
        "Offline Taxable Supply": data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.taxable_supply : 0,
        "Offline Commission %": data.offline_sale_id ? commissionPercentOfflineProduct : 0,
        "Service Order Price": data.service_id ? data.order_price : 0,
        "Service Taxable Amount": data.service_id ? parseFloat(data.taxable_amount) : 0,
        "Service Commission %": data.service_id ? commissionPercentService : 0,
        "Total Taxable Amount": parseValue(
            parseFloat(data.taxable_amount) + 
            parseFloat(data.logistics_order?.taxable_value || 0) * 
            (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100,
            'float'
        ),
        "TDS Amount": parseValue(
            (parseFloat(data.taxable_amount) + 
            parseFloat(data.logistics_order?.taxable_value || 0)) * 
            (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10,
            'float'
        ),
        "Net Amount": parseValue(
            parseFloat(data.taxable_amount) + 
            parseFloat(data.logistics_order?.taxable_value || 0) * 
            (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 - 
            parseFloat(data.taxable_amount) * 
            (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10,
            'float'
        ),
    }));        

        
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item"><Link to="/" className="text-dark">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/my-account" className="text-dark">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark">reffer info</Link></li>
                    </ol>
                </div>
            </div>

            <div className='row mb-4'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <label>Commission Type</label>
                    <select id="commission_type" className='form-control'>
                        <option value="">Select Commission Type</option>
                        <option value="sale_retail">Sale/Retail Commission</option>
                        <option value="retainer">Retainer Commission</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <button type="button" className='btn btn-primary btn-sm mt-4' onClick={fetchReferralData}>Submit</button>
                </div>
            </div>

            <div className='mb-3'>
                <button className='btn button-product me-2' onClick={() => exportToCSV(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to CSV</button>
                <button className='btn button-product me-2' onClick={() => exportToExcel(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to Excel</button>
                <button className='btn button-product' onClick={() => exportToPDF(commissionType === 'retainer' ? retainerColumnDefsdata : saleRetailColumnDefsdata, commissionType === 'retainer' ? "retainer commission" : "sale/Retail commission")}>Export to PDF</button>
            </div>

            <div className="ag-theme-alpine" style={{ height: 600 }}>
                <AgGridReact
                    rowData={userData}
                    columnDefs={commissionType === 'retainer' ? retainerColumnDefs : saleRetailColumnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    rowHeight={rowHeight}

                    // modules={AllCommunityModules}
                />
            </div>

            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                    <div className='mb-3'>
                        <button className='btn button-product me-2' onClick={() => exportToCSV(orderDetailsData, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details")}>Export to CSV</button>
                        <button className='btn button-product me-2' onClick={() => exportToExcel(orderDetailsData, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details")}>Export to Excel</button>
                        <button className='btn button-product' onClick={() => exportToPDF(orderDetailsData, commissionType === 'retainer' ? "retainer order details" : "sale/Retail order details")}>Export to PDF</button>
                    </div>
                    
                    <div className="ag-theme-alpine" style={{ height: 400 }}>
                        <AgGridReact
                            rowData={orderDetails}
                            columnDefs={orderDetailsColumnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={10}
                            rowHeight={rowHeight}

                            // modules={AllCommunityModules}
                        />
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserRefeanceDetails;