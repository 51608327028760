import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './business.css';
import { ToWords } from 'to-words';
import { parseValue } from '../utils/Common';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

function GenerateReturnInvoice() {
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceType, setInvoiceType] = useState(null);
    const location = useLocation();
    var counter = 0;
    console.log(invoiceData)
    useEffect(() => {
        setInvoiceData(location.state?.invoiceData || []);
        setInvoiceType(location.state?.invoiceType || null);
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }, [location.state]);

    const downloadPdfDocument = () => {
        const input = document.getElementById("invoice");
        if (!input) return;

        const a4Width = 595.28;
        const a4Height = 941.89;
        const padding = 0;
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: true,
            windowWidth: (a4Width + padding) * 2,
            windowHeight: a4Height * 2
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imgWidth = a4Width - padding * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight <= a4Height) {
                pdf.addImage(imgData, 'JPEG', padding, 0, imgWidth, imgHeight);
            } else {
                let y = 0;
                while (y < imgHeight) {
                    pdf.addImage(imgData, 'JPEG', padding, -y, imgWidth, imgHeight);
                    y += a4Height;
                    if (y < imgHeight) pdf.addPage();
                }
            }

            pdf.save('invoice.pdf');
        });
    }



    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        }
    });

    const convertToWords = (amount) => {
        const numAmount = parseFloat(amount);
        if (isNaN(numAmount)) return '';
        return toWords.convert(numAmount, { currency: true });
    };
    if (Array.isArray(invoiceData) && invoiceData.length > 0) {
        return (
            <>
                <div id='invoice'>
                    {
                        invoiceData.map((invoiceData) => {
                            counter = 1;
                            if (invoiceData) {
                                return (
                                    <>
                                        <div className='bill-customer' id="testId">
                                            <div className='invoice'>
                                                <div className='invoice-head col-md-12'>
                                                    <h2>{invoiceType} Invoice</h2>
                                                </div>
                                                <div className='customer-details'>
                                                    <div className='customer-details-head'>
                                                        <h2>Customer Details:</h2>
                                                    </div>
                                                    <div className='bill-details row'>
                                                        <div className='billing-address col-md-6'>
                                                            <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                                            <div className='billing-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>
                                                                        {`${invoiceData?.order_item?.order?.user?.first_name || ''} ${invoiceData?.order_item?.order?.user?.last_name || ''}`}
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.address || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.pincode || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.state || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pan No :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user?.pan_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Email :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user?.email || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mobile No :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user?.mobile_phone || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='shipping-address col-md-6'>
                                                            <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                                            <div className='shipping-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>
                                                                        {`${invoiceData?.order_item?.order?.user?.first_name || ''} ${invoiceData?.order_item?.order?.user?.last_name || ''}`}
                                                                    </div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.address || "N/A"}</div>

                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.pincode || "N/A"}</div>

                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.state || "N/A"}</div>

                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Email :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user?.email || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mobile No :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user?.mobile_phone || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='customer-details'>
                                                    <div className='customer-details-head'>
                                                        <h4>Supply by:</h4>
                                                    </div>
                                                    <div className='bill-details row'>
                                                        <div className='billing-address col-md-6'>
                                                            <div className='billing-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Name :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.user_business?.business_name || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Address :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.seller_addresses?.[0]?.address || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pincode :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.seller_addresses?.[0]?.pincode || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>GSTIN :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.seller_addresses?.[0]?.gst_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>State :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.seller_addresses?.[0]?.state || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Pan No :</label>
                                                                    <div className='col-md-6'>{invoiceData?.seller?.user_business?.pan_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Place of supply :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice_type == 'return_order_logistics' || invoiceData?.invoice_type == 'replace_order_logistics' ||  invoiceData?.invoice_type == 'cancel_order_logistics'? invoiceData?.order_item?.product_item?.product?.seller_address?.state : invoiceData?.seller?.seller_addresses?.[0]?.state || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Place of delivery :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.user_address?.state || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='shipping-address col-md-6'>
                                                            <div className='shipping-address-details'>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Invoice Number :</label>
                                                                    <div className='col-md-6'>{invoiceData?.invoice_no || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Invoice Date :</label>
                                                                    <div className='col-md-6'>{invoiceData?.created_at || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Order ID (SRRR Number) :</label>
                                                                    <div className='col-md-6'>{invoiceData?.order_item?.order?.order_id || "N/A"}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Mode of Payment  :</label>
                                                                    <div className='col-md-6'>Online</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Amount received  :</label>
                                                                    <div className='col-md-6'>Rs. {invoiceData?.price || 0}</div>
                                                                </div>
                                                                <div className='user-address row'>
                                                                    <label className='col-md-6'>Date & Time :</label>
                                                                    <div className='col-md-6'>{invoiceData?.created_at || "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='invoice-table'>
                                                    <div className='invoice-table-head'>
                                                        <h4>Goods wise Details:</h4>
                                                    </div>
                                                    <div className='invoice-table-body'>
                                                        <table className="orders-table report-margin table-responsive">
                                                            <thead>
                                                                <tr>
                                                                    <th>S.No.</th>
                                                                    <th>Product Name</th>
                                                                    <th>HSN/SAC Code</th>
                                                                    <th>Qty</th>
                                                                    <th>Rate</th>
                                                                    <th>Sale Amount</th>
                                                                    <th>Disc</th>
                                                                    <th>Taxable Supply</th>
                                                                    <th>IGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>CGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>SGST / UTGST
                                                                        <th>Rate %</th>
                                                                        <th>Amount</th>
                                                                    </th>
                                                                    <th>Total Invoice
                                                                        <th>GST</th>
                                                                        <th>Amount</th>
                                                                        <th>R. Off</th>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    invoiceData?.invoice_type == 'return_order_logistics' || invoiceData?.invoice_type == 'replace_order_logistics' ||  invoiceData?.invoice_type == 'cancel_order_logistics'?
                                                                        <tr>
                                                                            <td>1</td>
                                                                            <td>Logistics</td>
                                                                            <td>NA</td>
                                                                            <td>NA</td>
                                                                            <td>{parseValue(invoiceData?.logistics_order?.order_price || 0, 'float')}</td>
                                                                            <td>-{parseValue(invoiceData?.logistics_order?.order_price || 0, 'float')}</td>
                                                                            <td>-{parseValue(0, 'float')}</td>
                                                                            <td>-{parseValue(invoiceData?.logistics_order?.taxable_value || 0, 'float')}</td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.logistics_order?.igst_percent || 0, 'float')}</td>
                                                                                <td>-{parseValue(invoiceData?.logistics_order?.igst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.logistics_order?.cgst_percent || 0, 'float')}</td>
                                                                                <td>-{parseValue(invoiceData?.logistics_order?.cgst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{parseValue(invoiceData?.logistics_order?.sgst_percent || invoiceData?.logistics_order?.utgst_percent || 0, 'float')}</td>
                                                                                <td>-{parseValue(invoiceData?.logistics_order?.sgst || invoiceData?.logistics_order?.utgst || 0, 'float')}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>-
                                                                                    {
                                                                                        parseValue(invoiceData?.logistics_order?.igst || 0, 'float') +
                                                                                        parseValue(invoiceData?.logistics_order?.cgst || 0, 'float') +
                                                                                        parseValue(invoiceData?.logistics_order?.sgst || invoiceData?.logistics_order?.utgst || 0, 'float')
                                                                                    }
                                                                                </td>
                                                                                <td>-{parseValue(invoiceData?.logistics_order?.order_price || 0, 'float')}</td>
                                                                                <td>
                                                                                    {
                                                                                        parseValue(
                                                                                            parseValue(invoiceData?.logistics_order?.order_price || 0, 'float') -
                                                                                            parseValue(invoiceData?.logistics_order?.taxable_value || 0, 'float') -
                                                                                            parseValue(invoiceData?.logistics_order?.igst || 0, 'float') -
                                                                                            parseValue(invoiceData?.logistics_order?.cgst || 0, 'float') -
                                                                                            parseValue(invoiceData?.logistics_order?.sgst || invoiceData?.logistics_order?.utgst || 0, 'float'), 'float')
                                                                                    }
                                                                                </td>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        <>
                                                                            <tr>
                                                                                <td>{counter}</td>
                                                                                <td>
                                                                                    {invoiceData?.order_item?.product_item
                                                                                        ? invoiceData?.order_item?.product_item?.product?.name
                                                                                        : invoiceData?.order_item?.seller_service
                                                                                            ? invoiceData?.order_item?.seller_service?.service_name
                                                                                            : invoiceData?.order_item?.offline_sale_product_datum?.product_description || "N/A"}
                                                                                </td>
                                                                                <td>{invoiceData?.order_item?.product_item?.hsn_code || invoiceData?.order_item?.seller_service?.hsn_code || invoiceData?.order_item?.offline_sale_product_datum?.hsn_code || "N/A"}</td>
                                                                                <td>{invoiceData.order_item.offline_sale_product_datum?.quantity || invoiceData.order_item?.order_quantity || "N/A"}</td>
                                                                                <td>{parseValue(invoiceData.order_item.product_item?.price || invoiceData.order_item.seller_service?.price || invoiceData.order_item.offline_sale_product_datum?.price, 'float')}</td>
                                                                                <td>{parseValue(invoiceData.order_item?.rnote_value || invoiceData.order_item?.cancel_note_value, 'float')}</td>
                                                                                <td>{parseValue(invoiceData.order_item?.offline_sale_product_datum?.discount, 'float')}</td>
                                                                                <td>{parseValue(invoiceData.order_item?.rtaxable_value || invoiceData.order_item?.cancel_taxable_value || 0, 'float')}</td>
                                                                                <td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rigst_percent || invoiceData.order_item?.cancel_igst_percent, 'float')}</td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rigst || invoiceData.order_item?.cancel_igst || 0, 'float')}</td>
                                                                                </td>
                                                                                <td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rcgst_percent || invoiceData.order_item?.cancel_cgst_percent, 'float')}</td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rcgst || invoiceData.order_item?.cancel_cgst || 0, 'float')}</td>
                                                                                </td>
                                                                                <td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rsgst_percent || invoiceData.order_item?.rutgst_percent || invoiceData.order_item?.cancel_sgst_percent || invoiceData.order_item?.cancel_utgst_percent, 'float')}</td>
                                                                                    <td>{parseValue(invoiceData.order_item?.rsgst || invoiceData.order_item?.rutgst || invoiceData.order_item?.cancel_sgst || invoiceData.order_item?.cancel_utgst || 0, 'float')}</td>
                                                                                </td>
                                                                                <td>
                                                                                    <td>
                                                                                        {parseValue(
                                                                                            Number(invoiceData.order_item?.rigst || invoiceData.order_item?.cancel_igst || 0) +
                                                                                            Number(invoiceData.order_item?.rcgst || invoiceData.order_item?.cancel_cgst || 0) +
                                                                                            Number(invoiceData.order_item?.rsgst || invoiceData.order_item?.cancel_sgst || 0) +
                                                                                            Number(invoiceData.order_item?.rutgst || invoiceData.order_item?.cancel_utgst || 0),
                                                                                            'float'
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <td>{parseValue(invoiceData.order_item?.rnote_value || invoiceData.order_item?.cancel_note_value, 'float')}</td>
                                                                                        <td>
                                                                                            {parseValue(
                                                                                                (Number(invoiceData.order_item.rnote_value || invoiceData.order_item.cancel_note_value || 0)) -
                                                                                                (Number(invoiceData.order_item.rtaxable_value || invoiceData.order_item.cancel_taxable_value || 0)) -
                                                                                                (Number(invoiceData.order_item.rigst || invoiceData.order_item.cancel_igst || 0)) -
                                                                                                (Number(invoiceData.order_item.rcgst || invoiceData.order_item.cancel_cgst || 0)) -
                                                                                                (Number(invoiceData.order_item.rsgst || invoiceData.order_item.cancel_sgst || 0)) -
                                                                                                (Number(invoiceData.order_item.rutgst || invoiceData.order_item.cancel_utgst || 0)),
                                                                                                'float')
                                                                                            }
                                                                                        </td>
                                                                                    </td>

                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                }
                                                                <tr>
                                                                    <td colSpan="7"></td>
                                                                    <td colSpan="10">
                                                                        <tr>
                                                                            <td><b>Total</b></td>
                                                                            <td>Rs. {parseValue(invoiceData.price || 0, 'float')}</td>
                                                                            <td>{convertToWords(parseValue(invoiceData.price || 0, 'float'))}</td>

                                                                        </tr>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='footer-data row'>
                                                    <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                                    <h6 className='col-md-6'>No</h6>
                                                </div>
                                                <div className='footer-data row'>
                                                    <div className='col-md-12 row'>
                                                        <h5 className='col-md-6'>Remarks:</h5>
                                                        <div className='col-md-6 row'>
                                                            <label className='col-md-2'>for</label>
                                                            <div className='col-md-4'>
                                                                <b>{invoiceData.seller?.user_business?.business_name || "N/A"}</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='footer-data row'>
                                                    <div className='col-md-12 row'>
                                                        <h5 className='col-md-6'>Remarks:</h5>
                                                        <div className='col-md-6'>
                                                            <div className='Sign-image-vendor'>Signed</div>
                                                            <div className='Authorised-Signatory'>Authorised Signatory</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            } else {
                                return 'No Invoice';
                            }
                        })
                    }
                </div>
                <button onClick={downloadPdfDocument}>Download Pdf</button>
            </>


        )
    }
}

export default GenerateReturnInvoice;
