import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { Table, Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import { parseValue } from '../../utils/Common';

import $ from 'jquery';
import envConfig from '../../config/envConfig';
const common = require('../../utils/Common');

const OfflineSales = () => {
    let navigate = useNavigate();
    const [offlineSalesData, setOfflineSalesData] = useState([]);
    const [modal, setModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [productCount, setProductCount] = useState(1);
    const [productDetailsModal, setproductDetailsModal] = useState(false);
    const [productDetails, setproductDetails] = useState([]);
    const [num, setCount] = useState(2);
    console.log(offlineSalesData);
    const toggle = (data, type = 'enter') => {
        if (type == "enter" || type == "edit") {
            setModal(!modal);
            setEditData(data);
            type == "edit" ? setProductCount(data.offline_sale_product_data.length) : setProductCount(1);
        } else if (type == "view") {
            setproductDetailsModal(!productDetailsModal);
            setproductDetails(data);
        }
    }

    const getOfflineSalesData = async () => {
        let data = {
            'action': 'read',
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            setOfflineSalesData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const updateOfflineSalesData = async () => {
        let productData = [];
        for (let i = 1; i <= productCount; i++) {
            if ($('#product_description_' + i).val() == "" || $('#gst_' + i).val() == "" || $('#hsn_code_' + i).val() == "" || $('#quantity_' + i).val() == "" || $('#price_' + i).val() == "" || $('#sale_amount_' + i).val() == "" || $('#discount_' + i).val() == "" || $('#taxable_supply_' + i).val() == "") {
                common.alert("Please fill all fields. In case of numeric value add 0 if nothing is there.", "error");
                return;
            }
            let tempData = {
                'id': $('#id_' + i) ? $('#id_' + i).val() : 0,
                'operation_type': $('#id_' + i).val() ? 'update' : 'insert',
                'offline_sale_id': editData.id,
                'product_description': $('#product_description_' + i).val(),
                'hsn_code': $('#hsn_code_' + i).val(),
                'quantity': $('#quantity_' + i).val(),
                'price': $('#unit_price_' + i).val(),
                'sale_amount': $('#invoice_amount_' + i).val(),
                'discount': $('#discount_' + i).val(),
                'taxable_supply': $('#taxable_supply_' + i).val(),
                'igst_rate': 0,
                'igst_amount': 0,
                'cgst_rate': $('#cgst_rate_' + i).val(),
                'cgst_amount': $('#cgst_amount_' + i).val(),
                'sgst_utgst_rate': $('#sgst_rate_' + i).val(),
                'sgst_utgst_amount': $('#sgst_amount_' + i).val(),
            }
            productData.push(tempData);
        }
        let data = {
            'action': 'update_enter_details',
            'productData': productData
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            toggle(0);
            getOfflineSalesData();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const calculateAmountsAndTaxes = (id) => {
        let quantity = $('#quantity_' + id).val() ? parseInt($('#quantity_' + id).val()) : 0;
        let unitPrice = $('#unit_price_' + id).val() ? parseFloat($('#unit_price_' + id).val()) : 0;
        let discount = $('#discount_' + id).val() ? parseFloat($('#discount_' + id).val()) : 0;
        let gst = $('#gst_' + id).val() ? parseFloat($('#gst_' + id).val()) : 0;
        let taxableValue = parseValue(quantity * ((unitPrice - discount) / (100 + gst)) * 100, 'float');
        let cgstRate = parseValue(gst / 2, 'float');
        let cgstAmount = parseValue((taxableValue * cgstRate) / 100, 'float');
        let sgstRate = parseValue(gst / 2, 'float');
        let sgstAmount = parseValue((taxableValue * sgstRate) / 100, 'float');
        console.log(sgstAmount);
        console.log(cgstAmount);
        let totalGst = parseValue(cgstAmount + sgstAmount, 'float');
        console.log(totalGst);
        let invoiceValue = parseValue(quantity * (unitPrice - discount), 'float');
        let roundOff = parseValue(invoiceValue - taxableValue - cgstAmount - sgstAmount, 'float');
        $('#taxable_supply_' + id).val(taxableValue);
        $('#cgst_rate_' + id).val(cgstRate);
        $('#cgst_amount_' + id).val(cgstAmount);
        $('#sgst_rate_' + id).val(sgstRate);
        $('#sgst_amount_' + id).val(sgstAmount);
        $('#total_gst_' + id).val(totalGst);
        $('#invoice_amount_' + id).val(invoiceValue);
        $('#round_off_' + id).val(roundOff);
    }
    const addMoreProductDetails = (count) => {
        setCount(num + 1);
        count++;
        setProductCount(count);
        $('#product_details').append(`<tr>
            <td><input type="text" value="${count}"/></td>
            <td><input type="text" id="product_description_${count}"/></td>
            <td><input type="text" id="hsn_code_${count}"/></td>
            <td><input type="number" id="quantity_${count}"/></td>
            <td><input type="number" id="unit_price_${count}"/></td>
            <td><input type="number" id="discount_${count}"/></td>
            <td><input type="number" id="gst_${count}"/></td>
            <td><input type="number" id="taxable_supply_${count}" disabled /></td>
            <td className="nowrap">
                <input type="number" id="cgst_rate_${count}" disabled />&nbsp;
                <input type="number" id="cgst_amount_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="sgst_rate_${count}" disabled />&nbsp;
                <input type="number" id="sgst_amount_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="total_gst_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="invoice_amount_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="round_off_${count}" disabled />
            </td>
        </tr>`);

        $(`#quantity_${count}, #unit_price_${count}, #discount_${count}, #gst_${count}`).on('input', function () {
            calculateAmountsAndTaxes(count);
        });
    }

    useEffect(() => {
        getOfflineSalesData();
    }, []);

    const data = offlineSalesData.flatMap((item, index) => {
        // If there's no product data, return an empty array
        if (!item.offline_sale_product_data || item.offline_sale_product_data.length === 0) {
            return [];
        }

        // Get feedback and query
        let feedbackToShow = "No feedback available";
        let queryToShow = "No query available";

        if (item.offline_sale_product_data[0].order_items &&
            item.offline_sale_product_data[0].order_items.length > 0) {
            const orderItems = item.offline_sale_product_data[0].order_items;

            if (orderItems[0].feedbacks && orderItems[0].feedbacks.length > 0) {
                feedbackToShow = orderItems[0].feedbacks[orderItems[0].feedbacks.length - 1].user_feedback;
            }

            if (orderItems[0].querys && orderItems[0].querys.length > 0) {
                queryToShow = orderItems[0].querys[orderItems[0].querys.length - 1].user_query;
            }
        }

        // Map each product to a separate row
        return item.offline_sale_product_data.map((product, productIndex) => ({
            "S.No": `${index + 1}.${productIndex + 1}`,
            "Order Id": item.order ? item.order.order_id : 0,
            "Order Date": item.order ? item.order.created_at : 0,
            "User Invoice No": item.order && item.order.invoices
                ? (item.order.invoices.find(invoice => invoice.invoice_type === 'product')?.invoice_no || 0)
                : 0,
            "Seller Invoice No": item.order && item.order.invoices
                ? (item.order.invoices.find(invoice => invoice.invoice_type === 'seller_product')?.invoice_no || 0)
                : 0,
            "Buyer User Id": item.user ? item.user.reg_id : 0,
            "Buyer Name": item.user ? `${item.user.first_name} ${item.user.middle_name} ${item.user.last_name}` : 0,
            "User PAN": item.user ? item.user.pan_no : 0,
            "Seller User Id": item.seller ? item.seller.reg_id : 0,
            "Seller Name": item.seller ? `${item.seller.first_name} ${item.seller.middle_name} ${item.seller.last_name}` : 0,
            "Seller PAN": item.seller ? item.seller.pan_no : 0,
            "Seller GST No": item.seller_address ? item.seller_address.gst_no : 0,
            "Product Description": product.product_description || "",
            "HSN Code": product.hsn_code || "",
            "Quantity": product.quantity || 0,
            "Unit Price": product.price || 0,
            "Discount": product.discount || 0,
            "GST %": (product.sgst_utgst_rate + product.cgst_rate) || 0,
            "Taxable Supply": parseValue(product.taxable_supply || 0, 'float'),
            "SGST/UTGST Rate": parseValue(product.sgst_utgst_rate || 0, 'float'),
            "SGST/UTGST Amount": parseValue(product.sgst_utgst_amount || 0, 'float'),
            "CGST Rate": parseValue(product.cgst_rate || 0, 'float'),
            "CGST Amount": parseValue(product.cgst_amount || 0, 'float'),
            "Invoice Amount": parseValue(product.sale_amount || 0, 'float'),
            "Delivery Status": item.order ? "Done" : 0,
            "Feedback": feedbackToShow,
            "Query": queryToShow
        }));
    });
    const handleExportCSV = () => {
        exportToCSV(data, 'offline sale');
    }

    const handleExportExcel = () => {
        exportToExcel(data, 'offline sale');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'offline sale');
    }
    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>Offline Sales</h3>
                </div>
            </div>
            <div className="col-md-12">

                <Form className='row'>
                    <FormGroup className='col-md-4 row'>
                        <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                        <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOfflineSalesData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                    </FormGroup>
                    <FormGroup className='col-md-4 row'>
                        <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                        <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOfflineSalesData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                    </FormGroup>
                </Form>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>
            <div className='row tables'>
                <div className='col-md-12 '>
                    <div className='table-responsive table-container'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Order Id</th>
                                    <th>Order Date</th>
                                    <th>User Invoice No</th>
                                    <th>Seller Invoice No</th>
                                    <th>Buyer User Id</th>
                                    <th>Buyer Name</th>
                                    <th>User PAN</th>
                                    <th>Seller User Id</th>
                                    <th>Seller Name</th>
                                    <th>Seller PAN</th>
                                    <th>Seller GST No</th>
                                    <th>Products</th>
                                    <th>Total Taxable Supply</th>
                                    <th>Total Sale Amount</th>
                                    <th>CGST Amount</th>
                                    <th>SGST/UTGST Amount</th>
                                    <th>IGST Amount</th>
                                    <th>Delivery Status</th>
                                    <th>Feedback</th>
                                    <th>Query</th>
                                    <th>Enter/View Product Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offlineSalesData.map((data, index) => {
                                        const totalTaxableSupply = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => parseFloat(sum) + parseFloat(product.taxable_supply), 0) : 0;
                                        const totalSaleAmount = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => parseFloat(sum) + parseFloat(product.sale_amount), 0) : 0;
                                        const totalCGST = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => parseFloat(sum) + parseFloat(product.cgst_amount), 0) : 0;
                                        const totalSGST = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => parseFloat(sum) + parseFloat(product.sgst_utgst_amount), 0) : 0;
                                        const totalIGST = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => parseFloat(sum) + parseFloat(product.igst_amount), 0) : 0;
                                        let feedbackToShow = "No feedback available";
                                        let queryToShow = "No query available";

                                        if (data.offline_sale_product_data && data.offline_sale_product_data.length > 0) {
                                            const orderItems = data.offline_sale_product_data[0].order_items;

                                            if (orderItems && orderItems.length > 0) {
                                                const feedbacks = orderItems[0].feedbacks;
                                                if (feedbacks && feedbacks.length > 0) {
                                                    feedbackToShow = feedbacks[feedbacks.length - 1].user_feedback;
                                                }

                                                const querys = orderItems[0].querys;
                                                if (querys && querys.length > 0) {
                                                    queryToShow = querys[querys.length - 1].user_query;
                                                }
                                            }
                                        }

                                        const products = data.offline_sale_product_data;
                                        const productToShow = products && products.length > 1 ? "As per invoice" : (products && products.length === 1 ? products[0]['product_description'] : "No query available");
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.order ? data.order.order_id : "Product details submission or payment need to be done yet"}</td>
                                                    <td>{data.order ? data.order.created_at : "Product details submission or payment need to be done yet"}</td>
                                                    <td>
                                                        {data.order && data.order.invoices
                                                            ? (data.order.invoices.find(invoice => invoice.invoice_type === 'product') || { invoice_no: "" }).invoice_no : "Product details submission or payment need to be done yet"}
                                                    </td>
                                                    <td>
                                                        {data.order && data.order.invoices
                                                            ? (data.order.invoices.find(invoice => invoice.invoice_type === 'seller_product') || { invoice_no: "" }).invoice_no : "Product details submission or payment need to be done yet"}
                                                    </td>
                                                    <td>{data.user ? data.user.reg_id : ""}</td>
                                                    <td>{data.user ? data.user.first_name + " " + data.user.middle_name + " " + data.user.last_name : ""}</td>
                                                    <td>{data.user ? data.user.pan_no : ""}</td>
                                                    <td>{data.seller ? data.seller.reg_id : ""}</td>
                                                    <td>{data.seller ? data.seller.first_name + " " + data.seller.middle_name + " " + data.seller.last_name : ""}</td>
                                                    <td>{data.seller ? data.seller.pan_no : ""}</td>
                                                    <td>{data.seller_address ? data.seller_address.gst_no : ""}</td>
                                                    <td>{productToShow}</td>
                                                    <td>{parseValue(totalTaxableSupply, 'float')}</td>
                                                    <td>{parseValue(totalSaleAmount, 'float')}</td>
                                                    <td>{parseValue(totalCGST, 'float')}</td>
                                                    <td>{parseValue(totalSGST, 'float')}</td>
                                                    <td>{parseValue(totalIGST, 'float')}</td>
                                                    <td>{data.order ? 'Done' : 'Pending'}</td>
                                                    <td>{feedbackToShow}</td>
                                                    <td>{queryToShow}</td>
                                                    <td>
                                                        {
                                                            data.status == 0 ? <button className='btn btn-primary btn-sm' onClick={() => toggle(data)}>Enter Details</button> : <button className='btn btn-primary btn-sm' onClick={() => toggle(data.offline_sale_product_data, 'view')}>View Details</button>
                                                        }
                                                        {
                                                            data.status == 1 ? <button className='btn btn-primary btn-sm mt-3' onClick={() => toggle(data, 'edit')}>Edit Details</button> : null
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={() => toggle({}, 'enter')} size="lg" expand="md">
                <ModalHeader toggle={() => toggle({}, 'enter')}>Enter Product Details</ModalHeader>
                <ModalBody>
                    <div className='bill-customer' id="testId">
                        <div className='invoice'>
                            <div className='invoice-head col-md-12'>
                                <h2>Tax Invoice / Bill of Supply</h2>
                            </div>
                            <div className='customer-details'>
                                <div className='customer-details-head'>
                                    <h2>Customer Details:</h2>
                                </div>
                                <div className='bill-details row'>
                                    <div className='billing-address col-md-6'>
                                        <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                        <div className='billing-address-details'>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.user ? editData.user.first_name + " " + editData.user.last_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.pincode : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>state :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Pan No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.pan_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>E mail :</label><div className='col-md-6'>{editData && editData.user ? editData.user.email : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Mobile No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.mobile_phone : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='shipping-address col-md-6'>
                                        <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                        <div className='shipping-address-details'>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.user ? editData.user.first_name + " " + editData.user.last_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.pincode : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>State :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>E mail :</label><div className='col-md-6'>{editData && editData.user ? editData.user.email : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Mobile No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.mobile_phone : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='customer-details'>
                                <div className='customer-details-head'>
                                    <h4>Supply by:</h4>
                                </div>
                                <div className='bill-details row'>
                                    <div className='billing-address col-md-6'>
                                        {/* <div className='billing-address-head'><h4>Shipping By :</h4></div> */}
                                        <div className='billing-address-details'>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.seller && editData.seller.user_business ? editData.seller.user_business.business_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.pincode : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>GSTIN :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.gst_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>State :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Pan No :</label><div className='col-md-6'>{editData && editData.seller && editData.seller.user_business ? editData.seller.user_business.pan_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Place of supply :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Place of delivery :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.state : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='shipping-address col-md-6'>
                                        <div className='shipping-address-details'>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Invoice Number :</label><div className='col-md-6'>Will Generate After Adding Order Details</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Invoice Date :</label><div className='col-md-6'>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Order ID (SRRR Number) :</label><div className='col-md-6'>Will Generate After Adding Order Details</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Mode of Payment  :</label><div className='col-md-6'>Offline</div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Amount received  :</label><div className='col-md-6'>Rs. </div>
                                            </div>
                                            <div className='user-address row'>
                                                <label className='col-md-6'>Date & Time :</label><div className='col-md-6'>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='invoice-table'>
                                <div className='invoice-table-head'>
                                    <h4>Goods wise Details:</h4>
                                </div>
                                <div className='invoice-table-body'>
                                    <table class="orders-table report-margin table-responsive">
                                        <thead>
                                            <tr>
                                                <th>S. No.</th>
                                                <th>Product Description</th>
                                                <th>HSN / SAC Code</th>
                                                <th>Qty</th>
                                                <th>Unit Price</th>
                                                <th>Discount</th>
                                                <th>GST %</th>
                                                <th>Taxable Value</th>
                                                <th>CGST
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                </th>
                                                <th>SGST / UTGST
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                </th>
                                                <th>Total GST</th>
                                                <th>Invoice Value</th>
                                                <th>R. Off</th>
                                            </tr>
                                        </thead>
                                        <tbody id="product_details">
                                            {
                                                editData.offline_sale_product_data && editData.offline_sale_product_data.length != 0 ?
                                                    editData.offline_sale_product_data.map((product, index) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td><input type="text" defaultValue={index + 1} /></td>
                                                                    <td><input type="text" id={`product_description_${index + 1}`} defaultValue={product.product_description} /></td>
                                                                    <td><input type="text" id={`hsn_code_${index + 1}`} defaultValue={product.hsn_code} /></td>
                                                                    <td><input type="number" id={`quantity_${index + 1}`} defaultValue={product.quantity} onInput={() => calculateAmountsAndTaxes(index + 1)} /></td>
                                                                    <td><input type="number" id={`unit_price_${index + 1}`} defaultValue={product.price} onInput={() => calculateAmountsAndTaxes(index + 1)} /></td>
                                                                    <td><input type="number" id={`discount_${index + 1}`} defaultValue={product.discount} onInput={() => calculateAmountsAndTaxes(index + 1)} /></td>
                                                                    <td><input type="number" id={`gst_${index + 1}`} defaultValue={Number(product.sgst_utgst_rate) + Number(product.cgst_rate)} onInput={() => calculateAmountsAndTaxes(index + 1)} /></td>
                                                                    <td><input type="number" id={`taxable_supply_${index + 1}`} defaultValue={product.taxable_supply} disabled /></td>
                                                                    <td className="nowrap">
                                                                        <input type="number" id={`cgst_rate_${index + 1}`} defaultValue={product.cgst_rate} disabled />&nbsp;
                                                                        <input type="number" id={`cgst_amount_${index + 1}`} defaultValue={product.cgst_amount} disabled />
                                                                    </td>
                                                                    <td className="nowrap">
                                                                        <input type="number" id={`sgst_rate_${index + 1}`} defaultValue={product.sgst_utgst_rate} disabled />&nbsp;
                                                                        <input type="number" id={`sgst_amount_${index + 1}`} defaultValue={product.sgst_utgst_amount} disabled />
                                                                    </td>
                                                                    <td className="nowrap">
                                                                        <input type="number" id={`total_gst_${index + 1}`} defaultValue={Number(product.sgst_utgst_amount) + Number(product.cgst_amount)} disabled/>
                                                                    </td>
                                                                    <td className="nowrap">
                                                                        <input type="number" id={`invoice_amount_${index + 1}`} defaultValue={product.sale_amount} disabled />
                                                                    </td>
                                                                    <td className="nowrap">
                                                                        <input type="number" id={`round_off_${index + 1}`} defaultValue={parseValue(Number(product.sale_amount) - Number(product.taxable_supply) - Number(product.cgst_amount) - Number(product.sgst_utgst_amount), 'float')} disabled />
                                                                    </td>
                                                                    <input type="hidden" id={`id_${index + 1}`} defaultValue={product.id} disabled />
                                                                </tr>
                                                            </>
                                                        );
                                                    })
                                                    :
                                                    <tr>
                                                        <td><input type="text" value={1} /></td>
                                                        <td><input type="text" id="product_description_1" /></td>
                                                        <td><input type="text" id="hsn_code_1" /></td>
                                                        <td><input type="number" id="quantity_1" onInput={() => calculateAmountsAndTaxes(1)} /></td>
                                                        <td><input type="number" id="unit_price_1" onInput={() => calculateAmountsAndTaxes(1)} /></td>
                                                        <td><input type="number" id="discount_1" onInput={() => calculateAmountsAndTaxes(1)} /></td>
                                                        <td><input type="number" id="gst_1" onInput={() => calculateAmountsAndTaxes(1)} /></td>
                                                        <td><input type="number" id="taxable_supply_1" disabled /></td>
                                                        <td className="nowrap">
                                                            <input type="number" id="cgst_rate_1" disabled />&nbsp;
                                                            <input type="number" id="cgst_amount_1" disabled />
                                                        </td>
                                                        <td className="nowrap">
                                                            <input type="number" id="sgst_rate_1" disabled />&nbsp;
                                                            <input type="number" id="sgst_amount_1" disabled />
                                                        </td>
                                                        <td className="nowrap">
                                                            <input type="number" id="total_gst_1" disabled />
                                                        </td>
                                                        <td className="nowrap">
                                                            <input type="number" id="invoice_amount_1" disabled />
                                                        </td>
                                                        <td className="nowrap">
                                                            <input type="number" id="round_off_1" disabled />
                                                        </td>
                                                    </tr>
                                            }
                                        </tbody>
                                        <button type='button' className='btn btn-sm btn-primary' onClick={() => addMoreProductDetails(productCount)}>Add More</button>
                                    </table>
                                </div>
                            </div>
                            <div className='footer-data row'>
                                <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                <h6 className='col-md-6'>No</h6>
                            </div>
                            <div className='footer-data row'>
                                <div className='col-md-12 row'>
                                    <h5 className='col-md-6'>Remarks:</h5>
                                    <div className='col-md-6 row'>
                                        <label className='col-md-2'>for</label>
                                        <div className='col-md-4'>
                                            <b>{editData && editData.seller && editData.seller.user_business ? editData.seller.user_business.business_name : ''}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-data row'>
                                <div className='col-md-12 row'>
                                    <h5 className='col-md-6'>Remarks:</h5>
                                    <div className='col-md-6'>
                                        <div className='Sign-image-vendor'>Signed</div>
                                        <div className='Authorised-Signatory'>Authorised Signatory</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-success btn-sm' onClick={updateOfflineSalesData}>Submit</button>
                </ModalBody>
            </Modal>

            <Modal isOpen={productDetailsModal} toggle={() => toggle([], 'view')} size="lg" expand="md">
                <ModalHeader toggle={() => toggle([], 'view')}>Product Details</ModalHeader>
                <ModalBody>
                    <div className='row tables'>
                        <div className='col-md-12 '>
                            <div className='table-responsive table-container'>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Product Description</th>
                                            <th>HSN Code</th>
                                            <th>Quantity</th>
                                            <th>Unit Price</th>
                                            <th>Discount</th>
                                            <th>GST %</th>
                                            <th>Taxable Supply</th>
                                            <th>SGST/UTGST Rate</th>
                                            <th>SGST/UTGST Amount</th>
                                            <th>CGST Rate</th>
                                            <th>CGST Amount</th>
                                            <th>Invoice Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            productDetails.map((product) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{product.product_description ? product.product_description : ""}</td>
                                                            <td>{product.hsn_code ? product.hsn_code : ""}</td>
                                                            <td>{product.quantity ? product.quantity : 0}</td>
                                                            <td>{parseValue(product.price ? product.price : 0, 'float')}</td>
                                                            <td>{product.discount ? product.discount : 0}</td>
                                                            <td>{product.sgst_utgst_rate && product.cgst_rate ? parseFloat(product.sgst_utgst_rate) + parseFloat(product.cgst_rate) : 0}</td>
                                                            <td>{parseValue(product.taxable_supply ? product.taxable_supply : 0, 'float')}</td>
                                                            <td>{product.sgst_utgst_rate ? product.sgst_utgst_rate : 0}</td>
                                                            <td>{parseValue(product.sgst_utgst_amount ? product.sgst_utgst_amount : 0, 'float')}</td>
                                                            <td>{product.cgst_rate ? product.cgst_rate : 0}</td>
                                                            <td>{parseValue(product.cgst_amount ? product.cgst_amount : 0, 'float')}</td>
                                                            <td>{parseValue(product.sale_amount ? product.sale_amount : 0, 'float')}</td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default OfflineSales;