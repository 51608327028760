import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, CardBody, Card, Form, FormGroup, Label, Input, CardHeader, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileViewer from './FileView';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const UserDocs = () => {
  const [userData, setUserData] = useState([]);
  const [filePath, setFilePath] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const uploadPanImage = async (file) => {
      var fileData = new FormData();
      fileData.append('user_pan_image', file);
      var response = await common.apiRequest('post', '/users/upload-pan-image', fileData);
      setFilePath(response.data.message);
  }
  const fetchData = async () => {
    const data = {
      action: "read",
      'userId': localStorage.getItem('userId'),
      // source:"web"
    };

    try {
      let response = await common.apiRequest('post', '/users/crudUserDetails', data);
      if (response.data.code === "success") {
        setUserData(response.data.data);
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const updatepanpath = async () => {
    const data = {
      action: "updatepan",
      'userid': localStorage.getItem('userId'),
      // source:"web",
      user_pan_image:filePath,
      pan_status: "2"
    };
    try {
      let response = await common.apiRequest('post', '/users/crudUserDetails', data);
      if (response.data.code === "success") {
        alert(response.data.message);
        window.location.reload();
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  

  return (
    <div className='row tablessd mt-4'>
      <div className='col-md-12'>
            {/* <img
          src={userData && userData[0] && userData[0].user_pan_image 
          ? envConfig.api_url + userData[0].user_pan_image 
            : 'default_image_url'}                
            style={{ width: '30%', height: "auto", objectFit: 'contain' }}
                alt="User PAN"
                /> */}
                {userData && userData[0] && (
  <FileViewer 
    fileUrl={envConfig.api_url + (userData[0].user_pan_image || '')}
    fileType={userData[0].user_pan_image?.toLowerCase().endsWith('.pdf') ? 'pdf' : 'image'}
    style={{ width: '100%', height: 'auto' }}

  />
)}

      </div>
    
                            <div className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">PAN Photo/Pdf</Label>
				                <Input type="file" id="pan_image" onChange={(e) => uploadPanImage(e.target.files[0])} />
                                <Button onClick={updatepanpath}>Update</Button>
                            </div>
				            </div>

  )
}

export default UserDocs;
