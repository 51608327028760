import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cart.css'
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');


function CartView() {
  const [cartItems, setCartItems] = useState([]);
  const [cartSaveItem,setCartSaveItem] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userWallet, setUserWallet] = useState(0);

  console.log(cartItems)
  useEffect(() => {
    getCartItems();
  }, []);
  const getCartItems = async () => {
    let parameters = {
      'user_id': localStorage.getItem('userId'),
      'action': 'read'
    }
    let response = await common.apiRequest('post', '/products/add-remove-cart-items', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    let filteredData = data.filter(item => item.active === false);
    let filtereSaveItemData = data.filter(item => item.active === true);
    setCartSaveItem(filtereSaveItemData)
    calculateTotalPrice(data);
    setCartItems(filteredData);
    setUserWallet(response.data && response.data.wallet ? response.data.wallet : 0);
  }
 
  const removeFromCart = async (product_item_id) => {
    var localCartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (localCartItems) {
      const cartItemToRemove = localCartItems.find(o => o.product_item_id == product_item_id);
      cartItemToRemove.action = "delete";
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
      let data = (response.data) ? response.data : [];
      var updatedCartItems = localCartItems.filter(item => {
        return item.product_item_id != product_item_id;
      });
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      updatedCartItems = cartItems.filter(item => {
        return item.product_item_id != product_item_id;
      });
      setCartItems(updatedCartItems);
      calculateTotalPrice(updatedCartItems);
      common.alert(data.message, 'success');
      return;
    }
  }
  
  const handleCartItems = async (product_item_id, action) => {
    console.log('handleCartItems called with:', { product_item_id, action });

    let localCartItems = JSON.parse(localStorage.getItem('cartItems'));
    console.log('localCartItems:', localCartItems);

    if (!localCartItems || !Array.isArray(localCartItems)) {
      console.error('Invalid or empty localCartItems:', localCartItems);
      common.alert('Cart data is invalid or empty', 'error');
      return;
    }

    const cartItemToUpdate = localCartItems.find(o => String(o.product_item_id) === String(product_item_id));
    console.log('cartItemToUpdate:', cartItemToUpdate);

    if (!cartItemToUpdate) {
      console.error('Item not found in localCartItems. product_item_id:', product_item_id);
      common.alert('Item not found in cart', 'error');
      return;
    }

    const updatedItem = {
      ...cartItemToUpdate,
      action: action === 'save' ? "save" : "unSave",
      userId: localStorage.getItem('userId')
    };
    console.log('updatedItem:', updatedItem);

    try {
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', updatedItem);
      console.log('API response:', response);

      let data = response.data || {};

      if (data.code === 'success') {
        const updatedCartItems = localCartItems.map(item => 
          String(item.product_item_id) === String(product_item_id)
            ? { ...item, active: action === 'save' ? 1 : 0 }
            : item
        );

        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        await getCartItems();
        common.alert(data.message, 'success');
      } else {
        throw new Error(data.message || 'Failed to update cart');
      }
    } catch (error) {
      console.error('Error in handleCartItems:', error);
      common.alert(error.message || 'An error occurred', 'error');
    }
  };
  const calculateTotalPrice = (cartItems) => {
    const activeItems = cartItems.filter(item => item.active === false);
    let total_price = 0;
    activeItems.forEach((item) => {
      total_price += parseFloat(item.product_item ? item.product_item.price.replaceAll(',', '') : item.seller_service.price.toString().replaceAll(',', '')) * parseInt(item.quantity_purchased);
    });
  
    setTotalPrice(total_price);
  }
  

  return (
    <React.Fragment>
      <section class="h-100 gradient-custom">
        <div class="container py-5">
          <div class="row d-flex justify-content-center my-4">
            <div class="col-md-8">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <h5 class="mb-0">Cart: {cartItems.length} items</h5>
                </div>
                <div class="card-body">
                  {cartItems.length == 0 ? <h3>No items in cart</h3> :
                    cartItems.map((item) => {
                      return (
                        <div class="row">
                          <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                            <Link to={item.service_id ? '/service/' +'details/'+ item.service_id: '/product/'+item.product_id+'/'+item.product_item_id}>
                              <div class="">
                                <img   src={envConfig.api_url + (item.product_item && item.product_item.product_image  ? item.product_item.product_image.split(',')[0] 
                                      : (item.seller_service ? item.seller_service.service_image : '')
                                      )
                                    }
                                  class="userorderpageimg1" />
                                <a href="#!">
                                  <div class="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" }}></div>
                                </a>
                              </div>
                            </Link>
                          </div>

                          <div class="col-lg-5 col-md-6 mb-4 mb-lg-0">
                          <Link to={item.service_id ? '/service/' + item.service_id: '/product/'+item.product_id+'/'+item.product_item_id}>
                            <p className='cart_product_name'><strong>{item.product_item ? item.product_item.product.name : item.seller_service.service_name}</strong></p>
                          </Link>
                            <p className='cart_product_name'><strong>Qty:{item.quantity_purchased}</strong></p>
                            <button type="button" class="btn trash btn-warning  me-1 mb-2 mr-2" onClick={() => removeFromCart(item.product_item_id)}
                              title="Remove item">
                              {/* <i class="fas fa-trash"></i> */}
                              Remove
                            </button>
                            <button type="button" class="btn trash btn-dangers  mb-2" onClick={() => handleCartItems(item.product_item_id, 'save')}>
                              Save later
                            </button>

                          </div>

                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

                            <p class="text-start text-md-center">
                              <strong><i className="fas fa-rupee-sign" />{common.parseValue(item.product_item ? item.product_item.price : item.seller_service.price,'float')}</strong>
                            </p>

                          </div>
                        </div>

                      )
                    })
                  }
                  <hr class="my-4" />



                </div>
              </div>
              <div class="card mb-4">
                <div class="card-header py-3">
                  <h5 class="mb-0">Cart: {cartItems.length} items</h5>
                </div>
                <div class="card-body">
                  <h3>Saved Items</h3>
                  {cartSaveItem.length == 0 ? <h3>No items save in cart</h3> :
                    cartSaveItem.map((item) => {
                      return (
                        <div class="row">
                          <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
                            <Link to={item.service_id ? '/service/' + item.service_id: '/product/'+item.product_id+'/'+item.product_item_id}>
                              <div class="">
                                <img  src={envConfig.api_url + 
                                    (item.product_item && item.product_item.product_image 
                                      ? item.product_item.product_image.split(',')[0] 
                                      : (item.seller_service ? item.seller_service.service_image : '')
                                    )
                                  }
                                  class="userorderpageimg1" />
                                <a href="#!">
                                  <div class="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" }}></div>
                                </a>
                              </div>
                            </Link>
                          </div>

                          <div class="col-lg-5 col-md-6 mb-4 mb-lg-0">
                            <Link to={item.service_id ? '/service/' + item.service_id: '/product/'+item.product_id+'/'+item.product_item_id}>
                              <p className='cart_product_name'><strong>{item.product_item ?item.product_item.product.name:item.seller_service.service_name}</strong></p>
                            </Link>
                            <p className='cart_product_name'><strong>Qty:{item.quantity_purchased}</strong></p>
                            <button type="button" class="btn trash btn-dangers  mb-2"  onClick={() => handleCartItems(item.product_item_id, 'remove')}>
                              Move to cart
                            </button>
                          </div>

                          <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">

                            <p class="text-start text-md-center">
                              <strong><i className="fas fa-rupee-sign" />{common.parseValue(item.product_item ?item.product_item.price:item.seller_service.price,'float')}</strong>
                            </p>

                          </div>
                        </div>

                      )
                    })
                  }
                  <hr class="my-4" />



                </div>
              </div>
              <div class="card mb-4">
                <div class="card-body">
                  <p><strong>Expected shipping delivery</strong></p>
                  {/* <p class="mb-0">12.10.2020 - 14.10.2020</p> */}
                </div>
              </div>
              <div class="card mb-4 mb-lg-0">
                <div class="card-body">
                  <p><strong>We accept</strong></p>
                  <img class="me-2 mx-2" width="45px"
                    src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/visa.svg"
                    alt="Visa" />
                  <img class="me-2 mx-2" width="45px"
                    src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/amex.svg"
                    alt="American Express" />
                  <img class="me-2 mx-2" width="45px"
                    src="https://mdbcdn.b-cdn.net/wp-content/plugins/woocommerce-gateway-stripe/assets/images/mastercard.svg"
                    alt="Mastercard" />

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card mb-4">
                <div class="card-header py-3">
                  <h5 class="mb-0">Summary</h5>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products
                      <span><i className="fas fa-rupee-sign" />{common.parseValue(totalPrice,'float')}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                      {/* Shipping */}
                      {/* <span></span> */}
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>

                        <strong>
                          <p class="mb-0">(including VAT)</p>
                        </strong>
                        <strong>Total Item</strong>
                        <strong>
                          <p class="mb-0">({cartItems.length})</p>
                        </strong>
                      </div>
                      <span><strong><i className="fas fa-rupee-sign" />{common.parseValue(totalPrice,'float')}</strong></span>
                    </li>
                  </ul>
                  <div className='ahover'>
                    {
                      cartItems.length > 0 ?
                        <>
                          {userWallet > 0 ? "Rs. " + userWallet + " can be used from wallet on payment screen" : ""} <br/><br/>
                          <Link to="/cart/checkout/address"><button className="btn button-cart btn-warning btn-lg btn-block">Proceed to checkout</button></Link>
                        </>
                        :
                        <Link to="/home"><button className="btn button-cart btn-warning btn-lg btn-block">Go to home</button></Link>
                    }
                    <Link to="/home"><button className="btn button-cart btn-warning btn-lg btn-block mt-5">Countinue shopping</button></Link>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
export default CartView;
