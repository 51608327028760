import React from 'react'

const Amcp = () => {
  return (
    <div className='container broucher-image'>
        <img src="/assets/img/0.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/1.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/2.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/3.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/4.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/5.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/6.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/7.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/8.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/9.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/10.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/11.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/12.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/13.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/14.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/15.png" alt="Broucher" style={{width: '100%'}}></img>
        <img src="/assets/img/16.png" alt="Broucher" style={{width: '100%'}}></img>
    </div>  )
}

export default Amcp