import React from 'react';
import axios from 'axios';
const envConfig = require('../config/envConfig');

function QrCode() {
    const userId = localStorage.getItem('userId');
    const qrImageUrl = `${envConfig.api_url}/users/images/users/${userId}_qr.jpeg`;

    const handleDownload = async () => {
        try {
            const link = document.createElement('a');
            link.href = qrImageUrl;
            link.setAttribute('download', `${userId}_qr.jpeg`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log('Download failed:', error);
        }
    };

    return (
        <div className="row app">
            <div className='col-md-12'>
                <h1>Here is your QR code</h1>
                
                <img 
                    src={qrImageUrl}
                    alt="User QR Code"
                    className='card-img-top2'
                />
            </div>
            <div className='col-md-12'>
                <button onClick={handleDownload} className='QRbutton'>
                    Download
                </button>
            </div>
        </div>
    );
}

export default QrCode;
