import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './homepage.css';
import $ from 'jquery';
const common = require('../../../utils/Common');
const envConfig = require('../../../config/envConfig');

const MenuCard = ({ products, isLoading }) => {  // Added isLoading to props
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [addedToCart, setAddToCart] = useState({});
    const scrollPositionRef = useRef(0);
    
    const filteredProducts = products.filter((product) =>
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    
    useEffect(() => {
        // Capture scroll position before any state changes
        const currentScrollPosition = window.scrollY;
    
        // Perform async operations in a way that minimizes scroll disruption
        const performOperations = async () => {
            try {
                await Promise.all([
                    initializeCartStatus()
                ]);
            } catch (error) {
                console.error('Error in operations:', error);
            } finally {
                // Restore scroll position after operations complete
                requestAnimationFrame(() => {
                    window.scrollTo(0, currentScrollPosition);
                });
            }
        };
    
        performOperations();
    }, [products]);
    

    const initializeCartStatus = () => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const cartProductIds = cartItems.map(item => item.product_item_id);
        const newAddedToCart = {};
        products.forEach(product => {
            const productItemId = product.product_items[0].id;
            newAddedToCart[productItemId] = cartProductIds.includes(productItemId);
        });
        setAddToCart(newAddedToCart);
    };

    const saveScrollPosition = useCallback(() => {
        scrollPositionRef.current = window.scrollY;
    }, []);

    const restoreScrollPosition = useCallback(() => {
        requestAnimationFrame(() => {
            window.scrollTo(0, scrollPositionRef.current);
        });
    }, []);

    const addToCart = async (productId, productItemId, supplypincode, selfDeliver, sellerId, afterPackItemWeight, sellerAddressId) => {
        if (isLoading) return; // Now isLoading is properly defined
        saveScrollPosition();
        
        var cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
        let newCartItem = {
            'product_id': productId,
            'product_item_id': productItemId,
            'quantity_purchased': 1,
            'user_id': localStorage.getItem('userId'),
            'supply_pincode': supplypincode,
            'service_id': 0,
            'type': 'product',
            'action': 'create',
            'self_deliver': selfDeliver,
            'seller_id': sellerId,
            'seller_address_id': sellerAddressId,
            'after_pack_item_weight': afterPackItemWeight.replace('GRM', '').replace('grm', ''),
            'active': 0
        };

        try {
            let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
            let data = (response.data) ? response.data : [];
            newCartItem.cart_id = data.cart_id;
            delete newCartItem.user_id;
            delete newCartItem.action;
            cartItems.push(newCartItem);
            localStorage.setItem('cartItems', JSON.stringify(cartItems));

            setAddToCart((prev) => ({ ...prev, [productItemId]: true }));
            common.alert(data.message, 'success');
        } catch (error) {
            console.error('Error adding to cart:', error);
        } finally {
            restoreScrollPosition();
        }
    };

    const removeFromCart = async (productItemId) => {
        if (isLoading) return; // Now isLoading is properly defined
        saveScrollPosition();
        
        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (cartItems) {
            const cartItemToRemove = cartItems.find(o => o.product_item_id === productItemId);
            if (!cartItemToRemove) return;
            
            try {
                cartItemToRemove.action = "delete";
                let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
                let data = (response.data) ? response.data : [];

                const updatedCartItems = cartItems.filter(item => item.product_item_id !== productItemId);
                localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

                setAddToCart((prev) => ({ ...prev, [productItemId]: false }));
                common.alert(data.message, 'success');
            } catch (error) {
                console.error('Error removing from cart:', error);
            } finally {
                restoreScrollPosition();
            }
        }
    };

    return (
        <div className='mainproduct'>
            <div className="container-fluid bg-transparent my-4 p-3" style={{ position: "relative", maxWidth: "1400px" }}>
                <div className="row g-3">
                    {filteredProducts.map((product) => {
                        const productItemId = product.product_items[0].id;
                        return (
                            (product.product_items[0].remaining_stock==0 ?
                           ""
                            : <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={product.id} style={{ marginBottom: "30px" }}>
                            {/* <Link to={`/product/${product.id}/${product.product_items[0].id}`}> */}
                            <div className="card h-100 shadow-sm transparent-card">
                                <Link to={`/product/${product.id}/${product.product_items[0].id}`}>
                                    {product.product_items[0] && product.product_items[0].product_image ? 
                                        <img src={envConfig.api_url + product.product_items[0].product_image.split(',')[0]} className="card-img-top" alt="product img" />
                                        : <div className="card-img-top" alt="product img">

                                        </div>
                                    }
                                </Link>
                                <div className="label-top shadow-sm">
                                    <Link className="text-white" to={`/product/${product.id}/${product.product_items[0].id}`}>
                                        {product.name}
                                    </Link>
                                </div>
                                <div className="card-body">
                                    <div className="clearfix mb-3">
                                        <span className="float-start badge rounded-pill bg-warning">Rs. {common.parseValue(product.product_items[0].price,'float')}</span>
                                    </div>
                                    <div className="d-grid gap-2 my-4">
                                        {!addedToCart[productItemId] ? (
                                            <button className='btn btn-warning bold-btn' onClick={() => addToCart(product.id, productItemId, product.seller_address.pincode, product.product_items[0].self_deliver, product.seller_id, product.product_items[0].after_pack_item_weight, product.seller_address_gst)}>
                                                Add to Cart
                                            </button>
                                        ) : (
                                            <button className='btn btn-danger bold-btn' onClick={() => removeFromCart(productItemId)}>
                                                Remove from Cart
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* </Link> */}
                            
                        </div>)
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MenuCard;
