import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './css/deliverydetails.css';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const DeliveryDetails = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [nextStatus, setNextStatus] = useState(null);
  const [cannotChangeStatus, setCannotChangeStatus] = useState(false);

  useEffect(() => {
    checkOrders();
	},[]);

  const checkOrders = async () => {
    if (!location.state) {
        navigate('/delivery/products');
    }
    setData(location.state);
    const currentDeliverId = localStorage.getItem('delivery_userId');
    
    if (
      (location.state.deliver_id && !location.state.return_deliver_id && !location.state.replace_deliver_id && location.state.deliver_id == currentDeliverId) ||
      (location.state.return_deliver_id && location.state.deliver_id && !location.state.replace_deliver_id && location.state.return_deliver_id == currentDeliverId && location.state.deliver_id == currentDeliverId) ||
      (location.state.return_deliver_id && location.state.deliver_id && location.state.replace_deliver_id && location.state.return_deliver_id == currentDeliverId && location.state.deliver_id == currentDeliverId && location.state.replace_deliver_id == currentDeliverId) ||
      (location.state.return_deliver_id && !location.state.deliver_id && !location.state.replace_deliver_id && location.state.return_deliver_id == currentDeliverId) ||
      (location.state.replace_deliver_id && !location.state.deliver_id && !location.state.return_deliver_id && location.state.replace_deliver_id == currentDeliverId) ||
      (location.state.return_deliver_id && location.state.replace_deliver_id && location.state.return_deliver_id == currentDeliverId && location.state.replace_deliver_id == currentDeliverId) || 
      (location.state.deliver_id && location.state.return_deliver_id && !location.state.replace_deliver_id && location.state.return_deliver_id == currentDeliverId && location.state.deliver_id != currentDeliverId) ||
      (location.state.deliver_id && location.state.replace_deliver_id && !location.state.return_deliver_id && location.state.deliver_id == currentDeliverId && location.state.replace_deliver_id == currentDeliverId)
    ) {
      setCannotChangeStatus(false); 
    } else {
      setCannotChangeStatus(true);
    }
    setOrderStatuses(await common.getSystemConfig('product_service_order_status', 'delivery'));
  }

  const updateOrderStatus = async (orderId, orderType) => {
    if (nextStatus || $('#comment').val()) {
      var validStatuses = [];
      if (orderType == "product") {
        validStatuses = ['picked', 'ready_for_delivery', 'on_the_way', 'delivered', 'return_requested', 'returned', 'cancelled', 'cancel_requested', 'item_handover_to_seller'];
      } else {
        validStatuses = ['service_in_progress', 'service_completed', 'delivered'];
      }
      if (!validStatuses.includes($('#delivery_status').val())) {
        common.alert('You are not liable to change to this status', 'error');
        return;
      } else if ($('#delivery_status').val() == "process_amount_to_wallet") {
        common.alert('You are not liable to change status', 'error');
        return;
      }
      var parameters = {
        "order_id": orderId,
        "order_status": $('#delivery_status').val(),
        "order_remarks": $('#comment').val()
      };
      let response = await common.apiRequest('post', '/orders/change-order-status-operations', parameters, 'delivery');
      if (response.data.message) {
        common.alert(response.data.message);
        navigate('/delivery/products');
      } else {
        common.alert(response.data.error, 'error');
      }
    } else {
      common.alert('Please change status first', 'error');
    }
  }

  const uploadCancelReplaceReturnImages = async (event, orderItemId) => {
    const files = event?.target?.files;
    if (files.length > 0) {
      await common.uploadOrderImages(files, orderItemId, 'deliver', 'deliver_order', 1);
    } else {
      common.alert("No images to upload", "error");
    }
    $('#order_images').val(null);
  }

  if (data.product_item || data.seller_service) {
    return (
      <>
       <div class="container">
          <div class="row">
            
            <div id="form-header" class="col-12">
              <h1 id="title">Delivery details</h1>
            </div>
          </div> 
         
         <div class="row">   
             <div id="form-tagline" class="col-md-4">
                 <div class="form-tagline">
                     <h2>{data.product_item ? data.product_item.product.name : data.seller_service.service_name}</h2>
                     <img className='productdetails_img' src={envConfig.api_url + (data.product_item ? data.product_item.product_image.split(',')[0] : data.seller_service.service_image)} width="300" height="300"/>
                                   </div>
             </div>
            
             <div id="form-content" class="col-md-8">
                                   
                   <div class="row form-group">
                      <div class="col-sm-3">
                       <label id="name-label" class="control-label" for="name">*Name:</label>
                      </div>
                     
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-name"><i class="fa fa-user"></i></span>
                        </div>                   
                        <div className='form-control'>{data.order.user.first_name + data.order.user.last_name}</div>
                      </div>
                   </div>
                    
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="email-label" class="control-label" for="email">Pickup Address</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-address"><i class="fa-solid fa-location-dot"></i></span>
                        </div>
                        <div className='form-control'>{data.product_item ? data.product_item.product.seller_address.address : data.seller_service.seller_address.address}</div>
                      </div>
                   </div>
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="number-label" class="control-label" for="email">vendor Phone Number:</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-Phone"><i class="fa-solid fa-phone"></i></span>
                        </div>
                        <div className='form-control'>{data.seller.user_business.mobile}</div>
                      </div>
                    </div>
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="email-label" class="control-label" for="email">Delivery Address</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-address"><i class="fa-solid fa-location-dot"></i></span>
                        </div>
                        <div className='form-control'>{data.order.user_address.address}</div>
                      </div>
                   </div>
                    
                   <div class="form-group row">
                      <div class="col-sm-3">
                        <label id="number-label" class="control-label" for="email">customer Phone Number:</label>
                      </div>
                    
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-Phone"><i class="fa-solid fa-phone"></i></span>
                        </div>
                        <div className='form-control'>{data.order.user.mobile_phone}</div>
                      </div>
                    </div>
                    
                   <div class="form-group row">    
                      <div class="col-sm-3">
                        <label class="control-label"for="visit-purpose">Type of payment:</label>
                      </div>
                      
                      <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-pay"><i class="fa-solid fa-cash-register"></i></span>
                        </div>
                        <div className='form-control'>Online</div>
                        <div className='form-control'>{data.order_price}</div>
                      </div>     
                   </div>
              
                 <hr/>  
                 
                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="delivery_status">Delivery Status :</label>
                    </div>
                    <div class="input-group col-sm-9">
                      <>
                        <select id="delivery_status" disabled={cannotChangeStatus} defaultValue={data.order_status} className="form-control" onChange={(e) => setNextStatus(e.target.value)} style={{width: '130px'}}>
                            {
                                orderStatuses && orderStatuses.product && data.product_item ? Object.entries(orderStatuses.product).map(([key, value]) => (
                                    <option key={key} value={key} selected={data.order_status == key ? true: false}>{value}</option>
                                ))
                                :
                                orderStatuses && orderStatuses.service && data.seller_service ? Object.entries(orderStatuses.service).map(([key, value]) => (
                                    <option key={key} value={key} selected={data.order_status == key ? true: false}>{value}</option>
                                )) : null
                            }
                        </select>
                      </>
                    </div>  
                 </div>

                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="delivery_status">Order Images :</label>
                    </div>
                    <div class="input-group col-sm-9">
                      <>
                        <input type='file' id="order_images" onChange={(e) => uploadCancelReplaceReturnImages(e, data.id)} multiple/>
                      </>
                    </div>  
                 </div>

                 <div class="form-group row"> 
                    <div class="col-sm-3">
                      <label class="control-label" for="comment">Comments:</label>
                    </div>
                     
                    <div class="input-group col-sm-9">
                        <div class="input-group-prepend">
                           <span class="input-group-text" id="basic-addon-mail"><i class="fa fa-comment"></i></span>
                        </div>
                        <textarea class="form-control" rows="5" id="comment"></textarea>
                    </div>  
                 </div>
                   
                 <div class="form-group row">
                   <div class="col-sm-12">
                     <button class="btn btn-default" id="submit" onClick={() => updateOrderStatus(data.id, data.product_id ? 'product' : 'service')}>Submit</button>
                   </div>
                 </div>
   
           </div> 
                  
         </div>
       </div>
      </>
     )
  } else {
    return (<></>)
  }
}

export default DeliveryDetails